<script lang="ts">
import {Vue, Component, Prop, Emit, Watch} from 'vue-facing-decorator';
import {Application, Group, User} from "@/types";
import UserService from "@/services/user.service";
import {useTenantStore} from "@/stores/tenantStore";

@Component({})
export default class AssignUsers extends Vue {
  @Prop() private currentGroup!: any;
  @Prop() private removeUserFromGroup!: any;
  private tenantStore = useTenantStore();
  private usersToAdd: User[] = [];
  private users: User[] = [];
  private newUsers: Partial<User>[] = [];
  private newUser: User | null = null

  @Emit()
  private updateUsersToAdd() {
    return this.usersToAdd;
  }

  @Watch('usersToAdd', { deep: true })
  private onAppsUpdate() {
    this.updateUsersToAdd()
  }

  private mounted() {
    this.fetchUsers();
  }

  private async fetchUsers(take = 100, skip = 0, sort = 'users.first_name', desc = false, filters?: any, searchString?: string) {
    if (this.tenantStore?.tenantId) {
      this.users = await UserService.searchUsers(this.tenantStore.tenantId, take, skip, sort, desc, filters, searchString);
    }
  }

  private addUserRow() {
    this.newUsers.push({ first_name: '', last_name: '', email: '' });
  }

  private addAllUsers(group: Group | null) {
    if (group) {
      group.users = [];
      this.newUsers = [];
      this.usersToAdd = this.users;
    }
  }

  private removeAllUsers(group: Group | null) {
    if (group) {
      group.users = [];
      this.newUsers = [];
      this.usersToAdd = [];
    }
  }

  private addUserToGroup() {
    if (this.newUser && this.currentGroup) {
      this.usersToAdd = this.usersToAdd || []
      this.usersToAdd.push(this.newUser);
      this.newUser = null;
      this.newUsers = [];
    }
  }

  private removeNewUser(user: User) {
    this.usersToAdd = this.usersToAdd.filter((u) => u.id !== user.id);
  }

  private fullName(first: string, last: string): string {
    return `${first} ${last}`
  }

  private get filteredUsers() {
    return this.users.filter((user) => {
      const addingApp = this.usersToAdd.map((uta) => uta.id).includes(user.id);
      const hasApp = this.currentGroup?.users?.map((u: User) => u.id).includes(user.id);

      return !addingApp && !hasApp;
    });
  }
}
</script>

<template>
  <v-form>
    <v-row align="center">
      <v-col cols="12" class="pb-0">
        <v-btn block rounded="pill" color="primary" variant="flat" @click="addUserRow(currentGroup)" :disabled="!filteredUsers.length">Add user</v-btn>
      </v-col>
      <v-col cols="6" class="pb-0">
        <v-btn block rounded="pill" color="primary" variant="flat" @click="addAllUsers(currentGroup)" :disabled="!filteredUsers.length">Add all users</v-btn>
      </v-col>
      <v-col cols="6" class="pb-0">
        <v-btn block rounded="pill" color="primary" variant="flat" @click="removeAllUsers(currentGroup)">Remove all users</v-btn>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-list>
          <v-list-item v-for="user of currentGroup?.users" :key="user.email" style="background-color: rgba(0,0,0,0.05)" rounded class="my-2">
            <v-row no-gutters class="flex-nowrap" justify="space-between" align="center">
              <v-col cols="11">
                <v-list-item-title>{{ fullName(user.first_name, user.last_name) }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-col>
              <v-col cols="1">
                <v-list-item-action>
                  <v-dialog :attach="true">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" icon variant="flat" color="transparent" size="32"><v-icon icon="mdi-delete" color="primary" size="24"></v-icon></v-btn>
                    </template>
                    <template v-slot:default="{ isActive }">
                      <v-card max-width="460">
                        <v-card-title>Remove user from group</v-card-title>
                        <v-card-text>
                          Are you sure you want to remove this application from this group? This group must have no assigned users in any account in order to be deleted.
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              @click="isActive.value = false">
                            Cancel
                          </v-btn>
                          <v-btn
                              color="primary"
                              @click="isActive.value = false; removeUserFromGroup(user)">
                            Remove
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-list-item-action>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item v-for="user of usersToAdd" :key="user.email" style="background-color: rgba(0,0,0,0.05)" rounded class="my-2">
            <v-row no-gutters justify="space-between" align="center">
              <v-col cols="11">
                <v-list-item-title>{{ fullName(user.first_name, user.last_name) }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-col>
              <v-col cols="1">
                <v-list-item-action>
                  <v-btn icon variant="flat" color="transparent" size="32" @click="removeNewUser(user)"><v-icon icon="mdi-delete" color="primary" size="24"></v-icon></v-btn>
                </v-list-item-action>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item
              class="mb-1 px-0 pt-0"
              v-for="user of newUsers"
              :key="user.email"
          >
            <v-combobox
                class="app-combobox"
                clearable
                :items="filteredUsers"
                item-title="name"
                hide-details
                density="comfortable"
                placeholder="Search users"
                return-object
                :menu-props="{ maxWidth: 452 }"
            >
              <template v-slot:selection="{ item }">
                <div class="d-flex flex-column">
                  <v-list-item-title>{{ fullName(item.raw.first_name, item.raw.last_name) }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.raw.email }}</v-list-item-subtitle>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div class="d-flex flex-column">
                  <v-list-item @click="newUser = item.raw; addUserToGroup()">
                    <v-list-item-title>{{ fullName(item.raw.first_name, item.raw.last_name) }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.raw.email }}</v-list-item-subtitle>
                  </v-list-item>
                </div>
              </template>
            </v-combobox>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-form>
</template>

<style scoped>

</style>