<script lang="ts">
import {Component, Vue} from 'vue-facing-decorator';
import {useDisplay} from "vuetify";
import {Application, Group, Tenant} from "@/types";
import GroupService from "@/services/group.service";
import ApplicationService from "@/services/application.service";
import CreateGroup from "@/components/groups/CreateGroup.vue";
import TenantService from "@/services/tenant.service";
import AssignApplications from "@/components/groups/AssignApplications.vue";

@Component({
  components: {AssignApplications, CreateGroup}
})
export default class SystemGroupsManagement extends Vue {
  private mdAndUp = useDisplay().mdAndUp;
  private headers: Array<{ title: string; value: string; sortable: boolean; width?: string }> = [
    { title: 'Name', value: 'name', sortable: true },
    { title: 'Applications', value: 'applications', sortable: true },
    { title: 'Users', value: 'users', sortable: true },
    { title: '', value: 'actions', sortable: false },
  ];
  private group: Group = { name: '', userCount: 0 };
  private currentGroup: Group | null = null;
  private groups: Group[] = [];
  private selectedGroups: Group[] = [];
  private accounts: Tenant[] = [];

  private editGroupDialog = false;
  private removeAppDialog = false;
  private editGroupApplicationsDialog = false;
  private loading = false;
  private searchNames = '';

  private applications: Application[] = [];
  private appsToAdd: Application[] = [];
  private appsToKeep: Application[] = [];
  private currentApplication: Application | null = null;
  private newApps: Partial<Application>[] = [];
  private newApp: Application | null = null;


  private async mounted() {
    this.loading = true;
    try {
      await Promise.all([this.fetchSystemGroups(), this.fetchApplications(), this.fetchTenants()])
    } finally {
      this.loading = false;
    }
  }

  private async fetchSystemGroups() {
    this.groups = await GroupService.getSystemGroups();
  }

  private async fetchApplications() {
    this.applications = await ApplicationService.getAllApplications();
  }

  private async fetchTenants() {
    this.accounts = await TenantService.getTenants();
  }

  private removeNewApplication(app: Application) {
    this.appsToAdd = this.appsToAdd.filter((a) => a.id !== app.id);
  }

  private resetDialog() {
    this.group = {
      name: '',
      userCount: 0
    };
    this.appsToKeep = [];
    this.appsToAdd = [];
    this.newApps = [];
  }

  private async removeSystemGroups() {
    try {
      if (this.selectedGroups.length) {
        const groupIds = this.selectedGroups.map(group => group.id)
            .filter((id): id is number => id !== undefined);

        await GroupService.removeSystemGroups({groupIds});
      }
    } finally {
      this.selectedGroups = [];
      await this.fetchSystemGroups();
    }
  }

  private editGroup(group: Group) {
    this.editGroupDialog = true;
  }

  private async updateApplicationAccessForGroup(group: Group, remove = false) {
    try {
      const groupId = group.id || (await GroupService.createSystemGroup({ name: group.name })).id;
      const currentApps = group.applications || []
      this.appsToKeep = [...this.appsToAdd, ...currentApps];
      const applicationIdsToKeep = this.appsToKeep.map(app => app.id);

      await GroupService.updateApplicationAccessForSystemGroup(groupId, {
        applicationIds: applicationIdsToKeep
      });
    } finally {
      if (!remove) {
        this.editGroupApplicationsDialog = false;
        await this.fetchSystemGroups();
      }
    }
  }

  private async saveSystemGroup() {
    try {
      if (this.currentGroup?.id && !this.disabled) {
        await GroupService.updateSystemGroup({ id: this.currentGroup.id, name: this.currentGroup.name });
      }
    } finally {
      await this.fetchSystemGroups();
    }
  }

  private async removeSystemGroup() {
    try {
      if (this.currentGroup?.id) {
        await GroupService.removeSystemGroups({groupIds: [this.currentGroup?.id]});
      }
    } finally {
      this.selectedGroups = [];
      await this.fetchSystemGroups();
    }
  }

  private addApplicationAccess() {
    if (this.newApp && (this.currentGroup || this.group)) {
      this.appsToAdd = this.appsToAdd || []
      this.appsToAdd.push(this.newApp);
      this.newApp = null;
      this.newApps = [];
    }
  }

  private addApplicationRow(group: Group | null) {
    if (group) {
      this.newApps.push({ name: '', description: '' });
    }
  }

  private addAllApps(group: Group | null) {
    if (group) {
      group.applications = [];
      this.newApps = [];
      this.appsToAdd = this.applications;
    }
  }

  private removeAllApps(group: Group | null) {
    if (group) {
      group.applications = [];
      this.newApps = [];
      this.appsToAdd = [];
    }
  }


  private async removeApplicationAccessFromGroup(app: Application, andSave = false) {
    if (this.currentGroup?.applications) {
      this.appsToKeep = this.currentGroup.applications.filter((a) => a.id !== app.id) || [];
      this.currentGroup.applications = this.currentGroup.applications.filter((a) => a.id !== app.id) || [];

      if (andSave) {
        await this.updateApplicationAccessForGroup(this.currentGroup)
      }
    }
  }

  private get disabled() {
    return !(this.group.name || this.currentGroup?.name);
  }

  private get filteredApplications() {
    const group: any = this.currentGroup?.id ? this.currentGroup : this.group;

    return this.applications.filter((app) => {
      const addingApp = this.appsToAdd.map((atr) => atr.id).includes(app.id);
      const hasApp = group.applications?.map((a: Application) => a.id).includes(app.id);

      return !addingApp && !hasApp;
    });
  }
}
</script>

<template>
  <div>
    <v-row justify="space-between" align="center" dense>
      <v-col cols="12">
        <v-row align="center" dense>
          <v-col cols="12">
            <v-card color="#F4F4F4" variant="flat" class="pa-2" height="48">
              <v-row dense align="center">
                <v-col cols="6" md="8">
                  <v-row no-gutters align="center">
                    <v-col cols="5" md="auto">
                      <CreateGroup
                          :system="true"
                          :disabled="disabled"
                          :group="group"
                          :loading="loading"
                          :reset-dialog="resetDialog"
                          :removeApplicationAccessFromGroup="removeApplicationAccessFromGroup"
                          @fetchGroups="fetchSystemGroups"
                      />
                    </v-col>
                    <v-col cols="5" md="auto">

                      <v-dialog width="500">
                        <template v-slot:activator="{ props }">
                          <v-btn color="primary" v-bind="props" size="small" variant="plain" :disabled="!selectedGroups.length">
                            {{ mdAndUp ? 'Remove system groups' : 'Remove' }}
                          </v-btn>
                        </template>

                        <template v-slot:default="{ isActive }">
                          <v-card max-width="460">
                            <v-card-title>Remove {{ selectedGroups.length }} system group{{ selectedGroups.length > 1 ? 's' : ''}}?</v-card-title>
                            <v-card-text>
                              Are you sure you want to remove these system groups? Groups cannot have applications or users assigned, in order to be deleted.
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                  @click="isActive.value = false">
                                Cancel
                              </v-btn>
                              <v-btn
                                  :loading="loading"
                                  :disabled="loading"
                                  color="primary"
                                  @click="removeSystemGroups(); isActive.value = false">
                                Remove
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" md="4">
                  <v-row no-gutters>
                    <v-col cols="12" class="d-flex justify-end">
                      <v-text-field hide-details density="compact" placeholder="Search system groups" append-inner-icon="mdi-magnify" v-model="searchNames" variant="underlined"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
        :sort-by="[{key: 'name', order: 'asc'}]"
        :items="groups"
        :headers="headers"
        show-select
        return-object
        v-model="selectedGroups"
        height="calc(100vh - 260px)"
    >
      <template v-slot:[`item.users`]="{ item }">
        <v-chip color="primary" variant="flat" size="small" class="font-weight-bold">{{ item.userCount || 0 }} user{{ item.userCount > 1 || item.userCount === 0 ? 's' : '' }} in {{ accounts.length }} account{{ accounts.length > 1 || accounts.length === 0 ? 's' : '' }}</v-chip>

      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu>
          <template #activator="{ props }">
            <div class="d-flex justify-end">
              <v-btn v-bind="props" size="32" icon variant="flat" color="primary"><v-icon size="20">mdi-dots-vertical</v-icon></v-btn>
            </div>
          </template>
          <v-list density="compact">
            <v-list-item @click="currentGroup = {...item}; editGroup(item);" prepend-icon="mdi-pencil" title="Edit group"></v-list-item>
            <v-list-item @click="currentGroup = {...item}; editGroupApplicationsDialog = true;" prepend-icon="mdi-application-edit" title="Edit applications"></v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.applications`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex" v-for="(app, index) of item.applications" :key="app.id">
            <div class="mr-n2">
              <v-tooltip :text="app.name" location="bottom" open-delay="200" v-if="index < 5">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" size="32" style="border: 2px solid #ffffff" icon variant="flat" color="primary" @click="currentGroup = {...item}; currentApplication = app; removeAppDialog = true;">
                    <span v-if="!app.icon">{{ app.name.charAt(0) }}</span>
                    <v-icon v-else color="white" size="18">mdi-{{ app.icon }}</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </div>
          </div>
          <v-tooltip v-if="item.applications.length > 5" location="bottom" open-delay="200">
            <template v-slot:activator="{ props }">
              <div v-bind="props" class="ml-4"><a class="link">+ {{ item.applications.length - 5 }} more</a></div>
            </template>
            <template v-slot:default>
              <div v-for="app of item.applications.slice(5)" :key="app.id">{{ app.name }}</div>
            </template>
          </v-tooltip>
        </div>
      </template>
      
    </v-data-table>
    <v-dialog width="500" v-model="removeAppDialog">
      <template v-slot:default="{ isActive }">
        <v-card max-width="460">
          <v-card-title>Remove {{ currentApplication.name }} from {{ currentGroup.name }}?</v-card-title>
          <v-card-text>
            Are you sure you want to remove this application from this group? This group must have no assigned users in any account in order to be deleted.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                @click="isActive.value = false">
              Cancel
            </v-btn>
            <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                @click="removeApplicationAccessFromGroup(currentApplication, true); isActive.value = false">
              Remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog width="500" v-model="editGroupDialog">
      <template v-slot:default="{ isActive }">
        <v-card title="Edit system group">
          <v-card-text>
            <v-form>
              <v-row>
                <v-col cols="12">
                  <v-text-field hide-details density="compact" label="Group name" v-model="currentGroup.name"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                :loading="loading"
                :disabled="disabled"
                color="primary"
                @click="saveSystemGroup(); isActive.value = false"
            >
              Save system group
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog width="500" v-model="editGroupApplicationsDialog" @afterLeave="resetDialog">
      <template v-slot:default="{  }">
        <v-card title="And or remove group applications">
          <v-card-text>
            <p class="mb-4">Add applications to a group to easily assign applications to groups of users within User Management.</p>
            <AssignApplications
                :currentGroup="currentGroup"
                @updateAppsToAdd="appsToAdd = $event"
                :removeApplicationAccessFromGroup="removeApplicationAccessFromGroup"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                @click="updateApplicationAccessForGroup(currentGroup)"
                :loading="loading"
                :disabled="loading"
            >
              Save group
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>