<script lang="ts">
import {Vue, Component, Watch} from 'vue-facing-decorator';
import UserService from "@/services/user.service";
import {useUserStore} from "@/stores/userStore";
import {Application, User} from "@/types";
import ActionButton from "@/components/ActionButton.vue";
import {useDisplay} from "vuetify";
import AdminService from "@/services/admin.service";
@Component({ components: { ActionButton }})
export default class SuperUserManagement extends Vue {
  private mdAndUp = useDisplay().mdAndUp;
  private userStore = useUserStore();
  private loading = false;
  private users: User[] = [];
  private selectedUsers: User[] = [];
  private headers: Array<{ title: string; value: string; sortable: boolean; width?: string }> = [
    { title: 'Name', value: 'full_name', sortable: true },
    { title: 'Email', value: 'email', sortable: true },
    { title: 'Status', value: 'status', sortable: true }
  ];
  private searchNames = '';
  private user: { first_name: string; last_name: string; email: string; } = {
    first_name: '',
    last_name: '',
    email: '',
  }
  private async mounted() {
    await this.fetchSuperAdmins();
  }

  private async fetchSuperAdmins() {
    this.users = await AdminService.searchSuperUsers();
  }

  private async updateTableOptions(opts: any) {
    let sort = opts[0]?.key || 'full_name';
    if (sort === 'full_name') {
      sort = 'first_name';
    }
    this.users = await AdminService.searchSuperUsers(100, 0, `users.${sort}`, opts[0]?.order === 'desc', undefined)
  }

  @Watch('searchNames')
  private async search(string: string) {
    this.users = await AdminService.searchSuperUsers(100, 0, '', false, undefined, string)
  }

  private async addSuperUser() {
    this.loading = true;
    try {
      await AdminService.addSuperUser(this.user);
    } finally {
      this.loading = false;
      await this.fetchSuperAdmins();
    }
  }

  private async removeSuperUser() {
    if (this.selectedUsers.length) {
      for (const user of this.selectedUsers) {
        if (user.id) {
          await AdminService.removeSuperUser(user.id);
        }
      }
      this.selectedUsers = [];
      await this.fetchSuperAdmins();
    }
  }

  private initials(first: string, last: string): string {
    return `${first.charAt(0)} ${last.charAt(0)}`
  }

  private fullName(first: string, last: string): string {
    return `${first} ${last}`
  }

  private resetDialog() {
    this.user = {
      first_name: '',
      last_name: '',
      email: ''
    };
  }
}
</script>

<template>
  <div>
    <v-row justify="space-between" align="center" dense>
      <v-col cols="12">
        <v-row align="center" dense>
          <v-col cols="12">
            <v-card color="#F4F4F4" variant="flat" class="pa-2" height="48">
              <v-row dense align="center">
                <v-col cols="6" md="8">
                  <v-row no-gutters align="center">
                    <v-col cols="5" md="auto">
                      <v-dialog width="500" @update:modelValue="resetDialog()">
                        <template v-slot:activator="{ props }">
                          <v-btn v-bind="props" size="small" variant="plain" color="primary">
                            {{ mdAndUp ? 'Add admin user' : 'Add' }}
                          </v-btn>
                        </template>

                        <template v-slot:default="{ isActive }">
                          <v-card title="Add admin user">
                            <v-card-text>
                              <v-form>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-text-field hide-details density="compact" label="First name" v-model="user.first_name"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field hide-details density="compact" label="Last name" v-model="user.last_name"></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field hide-details density="compact" label="Email" v-model="user.email"></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>

                              <v-btn
                                  :loading="loading"
                                  :disabled="!user.first_name || !user.last_name || !user.email || loading"
                                  color="primary"
                                  @click="addSuperUser(); isActive.value = false"
                              >
                                Add admin user
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-col>
                    <v-col cols="5" md="auto">
                      <v-btn size="small" variant="plain" :disabled="!selectedUsers.length" color="primary" @click="removeSuperUser()">
                        {{ mdAndUp ? 'Remove admin user' : 'Remove' }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" md="4">
                  <v-row no-gutters>
                    <v-col cols="12" class="d-flex justify-end">
                      <v-text-field hide-details density="compact" placeholder="Search admin users" append-inner-icon="mdi-magnify" v-model="searchNames" variant="underlined"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
        :items="users"
        :headers="headers"
        show-select
        return-object
        v-model="selectedUsers"
        height="calc(100vh - 260px)"
        @update:sort-by="updateTableOptions"
    >
      <template v-slot:[`item.full_name`]="{ item }">
        {{ fullName(item.first_name, item.last_name) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div v-if="item.status === 'active'" class="d-flex">
          <v-icon start icon="mdi-circle" color="success"></v-icon>
          Active
        </div>
        <div v-if="item.status === 'pending'" class="d-flex">
          <v-icon start icon="mdi-circle" color="grey"></v-icon>
          Pending
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>

</style>
