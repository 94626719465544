import {createRouter, createWebHistory, RouteRecordRaw, RouterView} from "vue-router";
import ResetFlow from "@/views/login/ResetFlow.vue";
import { useUserStore } from '@/stores/userStore'
import Applications from "@/views/Applications.vue";
import SuperUserManagement from "@/views/admin/SuperUserManagement.vue";
import AccountsManagement from "@/views/admin/AccountsManagement.vue";
import UserManagement from "@/views/UserManagement.vue";
import {useTenantStore} from "@/stores/tenantStore";
import ApplicationManagement from "@/views/admin/ApplicationsManagement.vue";
import {Role} from "@/types";
import NotFound from "@/views/NotFound.vue";
import EmbeddedApplication from "@/views/EmbeddedApplication.vue";
import ApplicationConfiguration from "@/views/ApplicationConfiguration.vue";
import {h} from "vue";
import Manage from "@/views/Manage.vue";
import Admin from "@/views/admin/Admin.vue";
import {useAppStore} from "@/stores/appStore";
import TenantService from "@/services/tenant.service";
import NoApplications from "@/views/NoApplications.vue";
import SystemGroupsManagement from "@/views/admin/SystemGroupsManagement.vue";
import GroupManagement from "@/views/GroupManagement.vue";

export enum RouteScope {
  Public,
  Private,
  Admin
}

const routes: Array<any> = [
  {
    path: '/',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '/applications/:applicationId',
        name: 'application',
        component: EmbeddedApplication,
        meta: {
          accountIdRequired: true,
          scope: RouteScope.Private,
        }
      }
    ],
    meta: {
      accountIdRequired: true,
      scope: RouteScope.Private,
    },
  },
  {
    path: '/none',
    name: 'none',
    component: NoApplications,
    meta: {
      accountIdRequired: true,
      scope: RouteScope.Private,
    }
  },
  {
    path: '/manage',
    component: Manage,
    meta: {
      accountIdRequired: true,
      scope: RouteScope.Private,
    },
    children: [
      {
        path: '',
        name: 'users',
        component: UserManagement,
        meta: {
          accountIdRequired: true,
          scope: RouteScope.Private,
        }
      },
      {
        path: 'groups',
        name: 'group-management',
        component: GroupManagement,
        meta: {
          accountIdRequired: true,
          scope: RouteScope.Private,
        }
      },
    ]
  },
  {
    path: '/admin',
    component: Admin,
    children: [
      {
        path: '',
        name: 'accounts',
        component: AccountsManagement,
        meta: {
          scope: RouteScope.Admin,
        },
      },
      {
        path: 'superusers',
        name: 'superusers',
        component: SuperUserManagement,
        meta: {
          scope: RouteScope.Admin,
        },
      },
      {
        path: 'applications',
        name: 'application-management',
        component: ApplicationManagement,
        meta: {
          scope: RouteScope.Admin,
        },
      },
      {
        path: 'groups',
        name: 'system-groups',
        component: SystemGroupsManagement,
        meta: {
          scope: RouteScope.Admin,
        },
      }
    ],
      meta: {
      scope: RouteScope.Admin,
    },
  },
  {
    path: '/login',
    name: 'login',
    components: () => import('@/views/login/LoginFlow.vue'),
    children: [],
    meta: {
      accountIdRequired: false,
      scope: RouteScope.Public,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
    meta: {
      scope: RouteScope.Public,
      accountIdRequired: false
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetFlow,
    meta: {
      scope: RouteScope.Public,
    }
  },
  {
    path: '/knowledgebase',
    name: 'knowledgebase',
  },
  {
    path: '/privacy',
    name: 'privacy',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const appStore = useAppStore();
  const tenantStore = useTenantStore();
  // Are we looking at a protected route?


  if (!to.matched.some((record) => record.meta.scope === RouteScope.Public)) {
    // Everything behind authentication //
    const localStorageToken = localStorage.getItem('token');
    if (localStorageToken) {
      userStore.setToken(localStorage.getItem('token')!);

      // This works around Vue not waiting for refreshUser dispatch to complete
      const tenantId = localStorage.getItem('application_framework_tenant');
      if (tenantId) {
        await tenantStore.setTenantId(Number(tenantId));
        await tenantStore.getTenantTheme(Number(tenantId));

        if (!tenantStore.tenant) {
          await tenantStore.setTenant(await TenantService.getTenant(Number(tenantId)));
        }
      }
      await userStore.refreshUser();
    }
    // Always set pre-login route in case of 401
    userStore.preLoginRoute = to.fullPath;

    // Check we actually have an API token otherwise hit the login
    if (!userStore.appFrameworkToken && (!localStorageToken || localStorageToken === 'null')) {
      next({name: 'login'});
      return;
    }
    appStore.toggleAppLoading(true);

    if (to.matched.some((record) => record.meta.scope === RouteScope.Admin)) { // Are we on a admin route, if so do we have permissions, if not goto home
      if (!userStore.user.roles.map((r: Role) => r.id).includes(3)) {
        next({ name: 'application', params: { applicationId: userStore.lastActiveApplication } });
        await tenantStore.getTenantTheme();
        return;
      }
    } else if (!tenantStore.tenantId) {
      if (
        userStore.permissions &&
        userStore.permissions.length &&
        userStore.permissions.some((p: string) => p.includes('administration:'))
      ) {
        if (!to.path.includes('admin')) {
          next('/admin')
          return;
        }
      }
    } else {
      if (to.matched.some((record) => record.path === '/')) {
        if (userStore.apps?.length === 1) {
          const userIsTenantUser = userStore.user.roles.find((r: { slug: string }) => r.slug === 'user');
          if (to.name !== 'application') {
            if (userIsTenantUser) {
              next({name: 'application', params: {applicationId: userStore.apps?.[0].id}})
              return;
            }
          }
        } else if (!userStore.apps?.length) {
          if (to.name !== 'none') {
            next({name: 'none'})
            return;
          }
        }
        if (to.name !== 'application') {
          if (userStore?.isSuperAdmin) {
            if (!to.path.includes('admin')) {
              next('/admin')
              return;
            }
          }
          if (userStore?.isManager) {
            if (to.name !== 'manage') {
              next('/manage')
              return;
            }
          }
          if (!userStore.lastActiveApplication && !to.params.applicationId) {
            // No last active app and no appId in the route -> Redirect to first available application
            if (userStore && userStore?.apps.length) {
              next({name: 'application', params: {applicationId: userStore.apps?.[0].id}});
            } else {
              next({name: 'none'})
            }
          } else if (userStore.lastActiveApplication) {
            //  Has last active app and has access -> Redirect to lastActiveApplication
            next({name: 'application', params: {applicationId: userStore.lastActiveApplication}});
          } else {
            //  Proceed with navigation (fallback case)
            next();
          }

          return;
        }
      }
    }
  }
  next();
});

router.afterEach((to, from) => {
  const appStore = useAppStore();
  setTimeout(() => {
    appStore.toggleAppLoading(false);
  }, 300)

})

export default router;
