<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import QuicksightDashboard from "@/components/applications/QuicksightDashboard.vue";
import ApplicationService from "@/services/application.service";
import {Application} from "@/types";
import TenantService from "@/services/tenant.service";
import {useTenantStore} from "@/stores/tenantStore";
import ExternalApp from "@/components/applications/ExternalApp.vue";
import BlankApp from "@/components/applications/BlankApp.vue";

@Component({})
export default class EmbeddedApplication extends Vue {
  private application: any = null;
  private embed: { url: string } = { url: '' };

  private async mounted() {
    if (this.$route.params && this.$route.params.applicationId) {
      const tenantStore = useTenantStore();
      if (tenantStore.tenantId) {
        const id = Array.isArray(this.$route.params.applicationId) ? this.$route.params.applicationId[0] : this.$route.params.applicationId;
        this.application = await TenantService.getApplication(tenantStore.tenantId, Number(id));

        this.embed = await TenantService.getEmbedUrl(tenantStore.tenantId, Number(this.$route.params.applicationId));
      }
    }
  }
  private get applicationType() {
    switch (this.application?.type) {
      case 'quicksight': return QuicksightDashboard;
      case 'external': return ExternalApp;
      default: return ExternalApp;
    }
  }
}
</script>

<template>
  <div class="fill-height">
    <component :is="applicationType" :url="embed.url" v-if="application && application.type && applicationType"></component>
  </div>
</template>

<style>
.embed-container {
  line-height: 0;
}
</style>
