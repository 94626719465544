import {appAxios} from "@/app.axios";

export class GroupService {
  public async getAllGroups(tenantId: number | null) {
    let url = `tenants/${tenantId}/groups`;
    if (tenantId) {
      url += `?tenantId=${tenantId}`
    }
    return (await appAxios.get(url)).data;
  }

  public async createGroup(body: { name: string, tenantId: number }) {
    const url = `tenants/${body.tenantId}/groups`;
    return (await appAxios.post(url, body)).data;
  }

  public async createGroupWithApplicationsAndUsers(body: { name: string, tenantId: number | null, applicationIds: number[], userIds: number[] }) {
    const url = `tenants/${body.tenantId}/groups/wizard`;
    return (await appAxios.post(url, body)).data;
  }

  public async createSystemGroupWithApplicationsAndUsers(body: { name: string, tenantId: number | null, applicationIds: number[], userIds: number[] }) {
    const url = `admin/groups/wizard`;
    return (await appAxios.post(url, body)).data;
  }

  public async updateGroup(groupId: number, body: { id: number; name: string; tenantId: number; }) {
    const url = `tenants/${body.tenantId}/groups/${groupId}`
    return (await appAxios.put(url, body)).data;
  }

  public async updateUsersInGroup(groupId: number, body: { userIds: number[], tenantId: number | null }) {
    const url = `tenants/${body.tenantId}/groups/${groupId}/users`
    return (await appAxios.put(url, body)).data;
  }

  public async updateUsersInGroups(body: { usersToKeep: { userId: number, groupId: number }[] }, tenantId?: number | null) {
    const url = `tenants/${tenantId}/groups/users`
    return (await appAxios.put(url, body)).data;
  }

  public async getSystemGroups() {
    const url = `/admin/groups`;
    return (await appAxios.get(url)).data;
  }

  public async createSystemGroup(group: { name: string }) {
    const url = `/admin/groups`;
    return (await appAxios.post(url, group)).data;
  }

  public async updateSystemGroup(group: { id: number; name: string }) {
    const url = `/admin/groups/${group.id}`;
    return (await appAxios.put(url, group)).data;
  }

  public async removeSystemGroups(body: { groupIds: number[] }) {
    const url = `/admin/groups`;
    return (await appAxios.put(url, body)).data;
  }

  public async removeUserGroups(body: { groupIds: number[] }, tenantId: number | null) {
    const url = `tenants/${tenantId}/groups`;
    return (await appAxios.put(url, body)).data;
  }

  public async removeUserFromGroup(groupId: number, userId: number, tenantId: number | null) {
    const url = `tenants/${tenantId}/groups/${groupId}/users/${userId}`;
    return (await appAxios.delete(url)).data;
  }

  public async updateUserGroups(userId: number, body: { groupIds: number[] }, tenantId: number | null) {
    const url = `tenants/${tenantId}/groups/users/${userId}`;
    return (await appAxios.put(url, body)).data;
  }

  public async updateApplicationAccessForGroup(groupId: number, body: { applicationIds: number[] }, tenantId: number | null) {
    const url = `tenants/${tenantId}/groups/${groupId}/applications`
    return (await appAxios.put(url, body)).data;
  }

  public async updateApplicationAccessForSystemGroup(groupId: number, body: { applicationIds: number[] }) {
    const url = `admin/groups/${groupId}/applications`
    return (await appAxios.put(url, body)).data;
  }
}

export default new GroupService();
