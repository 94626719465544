<template>
  <div class="fill-height overflow-x-hidden" :class="smAndDown ? 'background-mob' : 'background'">
    <v-row class="relative z99 fill-height">
      <v-col cols="12" class="absolute centered">
        <v-row justify="start" class="relative">
          <v-col cols="auto" class="absolute">
            <v-img :src="landscapeLogoSrc" cover :max-width="smAndDown ? 200 : 350" class="py-2 mx-2 float-right"/>
          </v-col>
        </v-row>
        <v-stepper v-model="activeStep" flat class="fill-height" id="login-stepper">
          <v-stepper-window :model-value="activeStep" class="fill-height">
            <v-stepper-window-item :value="ResetSteps.EmailPrompt" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <EmailPrompt
                      @checkEmail="username = $event; checkUser($route.query.username ? $route.query.username : username);"
                      :query-username="$route.query.username ? $route.query.username : username"
                      :error="error"
                      @clearError="error = ''"
                  />
                </v-col>
              </v-row>
            </v-stepper-window-item>
            <v-stepper-window-item :value="ResetSteps.ActivationPrompt" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <ActivationPrompt
                      :info="info"
                      :username="$route.query.username ? $route.query.username : username"
                      :query-code="$route.query.code ? $route.query.code : null"
                      @success="activeStep = ResetSteps.Success"
                      @go-to:resend="activeStep = ResetSteps.Resend"
                  />
                </v-col>
              </v-row>
            </v-stepper-window-item>
            <v-stepper-window-item :value="ResetSteps.Resend" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <ResendPrompt @go-to:email="activeStep = ResetSteps.EmailPrompt" :username="$route.query.username ? $route.query.username : username" :activating="false"/>
                </v-col>
              </v-row>
            </v-stepper-window-item>
            <v-stepper-window-item :value="ResetSteps.Success" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <v-row justify="center" align="center">
                    <v-col cols="12" md="5" class="pa-4">
                      <v-card color="primary" class="px-4 py-6">
                        <v-row no-gutters align="center" justify="center">
                          <v-col cols="12" class="text-center">
                            <span class="text-white text-h5 font-weight-bold">Your password has been changed.</span>
                          </v-col>
                          <v-col cols="12" class="my-6 text-center">
                            <span class="text-white text-body-1">Please log in to proceed.</span>
                          </v-col>
                          <v-col class="d-flex justify-center">
                            <v-btn width="95" @click="$router.push({name: 'login', query: {username: username}})" color="secondary">Log in</v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-window-item>
          </v-stepper-window>
        </v-stepper>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue, toNative, Emit } from "vue-facing-decorator";
import EmailPrompt from "@/views/login/EmailPrompt.vue";
import ActivationPrompt from "@/views/login/ActivationPrompt.vue";
import LoginService from "@/services/login.service";
import ActionButton from "@/components/ActionButton.vue";
import ResendPrompt from "@/views/login/ResendPrompt.vue";
import { useDisplay } from 'vuetify';

enum ResetSteps {
  EmailPrompt,
  ActivationPrompt,
  Success,
  Resend
}

@Component({
  components: {ResendPrompt, ActionButton, ActivationPrompt, EmailPrompt}
})
export default class ResetFlow extends Vue {
  private username = '';
  private error = '';
  private ResetSteps = ResetSteps;
  private activeStep = ResetSteps.EmailPrompt;
  private info = '';
  private smAndDown = useDisplay().smAndDown;

  private mounted() {
    if (this.$route.params.step === 'ActivationPrompt') {
      this.activeStep = ResetSteps.ActivationPrompt;
      // this.info = this.$route.params.info as string; // TODO: Fix for 3
    }
  }

  private async checkUser(username: string) {
    try {
      await LoginService.getUserStatus(username);
      this.activeStep = ResetSteps.ActivationPrompt;
    } catch (err) {
      this.error = (err as Error).message;
    }
  }
  private get landscapeLogoSrc() {
    return '';
    // return require('@/assets/UK-Engineers-Logo_landscape.png')
  }
}
</script>

<style scoped>
#login-stepper {
  background-color: transparent;
}
#login-stepper .v-stepper__wrapper {
  height: 100% !important;
}
.background {
  background-color: #fafafb;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.background-mob {
  background-color: #fafafb;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>