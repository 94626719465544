<script lang="ts">
import {Vue, Component, Watch} from 'vue-facing-decorator';
import {useDisplay} from "vuetify";
import GroupService from "@/services/group.service";
import {useTenantStore} from "@/stores/tenantStore";
import {Application, Group, User} from "@/types";
import CreateGroup from "@/components/groups/CreateGroup.vue";
import UserService from "@/services/user.service";
import ApplicationService from "@/services/application.service";
import {useUserStore} from "@/stores/userStore";
import AssignApplications from "@/components/groups/AssignApplications.vue";
import AssignUsers from "@/components/groups/AssignUsers.vue";

@Component({
  components: {AssignUsers, AssignApplications, CreateGroup}
})
export default class GroupManagement extends Vue {
  private mdAndUp = useDisplay().mdAndUp;
  private tenantStore = useTenantStore();
  private userStore = useUserStore();
  private combinedGroups: Group[] = [];
  private expandedGroups: Group[] = []
  private group: Group = { name: '', userCount: 0 };
  private currentGroup: Group | null = null;
  private loading = false;
  private searchNames = '';
  private headers: Array<{ title: string; value: string; sortable: boolean; width?: string }> = [
    { title: '', value: 'data-table-expand', sortable: false, width: '3%' },
    { title: '', value: 'data-table-select', sortable: false, width: '3%' },
    { title: 'Group name', value: 'name', sortable: true },
    { title: 'Users', value: 'users', sortable: true },
    { title: 'Applications', value: 'applications', sortable: true },
    { title: 'Group type', value: 'access', sortable: true },
    { title: '', value: 'actions', sortable: false  },
  ];
  private subHeaders: Array<{ title: string; value: string; sortable: boolean; width?: string }> = [
    { title: 'Full name', value: 'full_name', sortable: true, width: '20%'},
    { title: 'Email', value: 'email', sortable: false },
    { title: '', value: 'actions', sortable: false  },
  ];

  private editUsersDialog = false;
  private editGroupDialog = false;
  private editGroupApplicationsDialog = false;

  private selectedGroups: Group[] = [];

  private applications: Application[] = [];
  private appsToKeep: Application[] = [];
  private appsToAdd: Application[] = [];
  private currentApplication: Application | null = null;
  private newApp: Application | null = null;

  private users: User[] = [];
  private selectedUsers: { [key: number]: User[] } = {};
  private usersToAdd: User[] = [];
  private usersToKeep: User[] = [];
  private userCount: { [key: number]: number } = {};


  private async mounted() {
    await Promise.all([this.fetchGroups(), this.fetchUsers(), this.fetchApplications()])
    for (const group of this.combinedGroups) {
      if (group?.id) {
        this.selectedUsers[group.id] = this.selectedUsers[group.id] || [];
      }
    }
  }

  private async fetchUsers(take = 100, skip = 0, sort = 'users.first_name', desc = false, filters?: any, searchString?: string) {
    if (this.tenantStore?.tenantId) {
      this.users = await UserService.searchUsers(this.tenantStore.tenantId, take, skip, sort, desc, filters, searchString);
    }
  }

  private async fetchGroups() {
    if (this.tenantStore?.tenantId) {
      this.combinedGroups = await GroupService.getAllGroups(this.tenantStore.tenantId)
      for (const group of this.combinedGroups) {
        if (group?.id && group.users) {
          this.userCount[group.id] = group.userCount || 0;
        }
      }
    }
  }


  private async fetchApplications() {
    if (this.tenantStore?.tenantId) {
      this.applications = await ApplicationService.getApplicationsForTenant(this.tenantStore.tenantId);
    }
  }

  private async createUserGroup() {
    try {
      if (this.group && !this.disabled && this.tenantStore?.tenantId) {
        const body = {
          name: this.group.name,
          tenantId: this.tenantStore.tenantId
        }
        await GroupService.createGroup(body);
      }
    } finally {
      await this.fetchGroups();
    }
  }


  private async saveUserGroup() {
    try {
      if (this.currentGroup?.id && !this.disabled && this.tenantStore?.tenantId) {
        await GroupService.updateGroup( this.currentGroup.id,{ id: this.currentGroup.id, name: this.currentGroup.name, tenantId: this.tenantStore.tenantId });
      }
    } finally {
      await this.fetchGroups();
    }
  }

  private async removeUserGroup() {
    try {
      if (this.selectedGroups.length) {
        const groupIds = this.selectedGroups.map(group => group.id)
            .filter((id): id is number => id !== undefined);

        await GroupService.removeUserGroups({groupIds}, this.tenantStore.tenantId);
      }
    } finally {
      this.selectedGroups = [];
      await this.fetchGroups();
    }
  }


  private editGroup(group: Group) {
    this.editGroupDialog = true;
  }

  private async updateApplicationAccessForGroup(group: Group, remove = false) {
    try {
      const currentApps = group.applications || []
      this.appsToKeep = [...this.appsToAdd, ...currentApps];
      const applicationIdsToKeep = this.appsToKeep.map(app => app.id);
      if (group?.id) {
        await GroupService.updateApplicationAccessForGroup(group.id, {
          applicationIds: applicationIdsToKeep
        }, this.tenantStore.tenantId);
      }
    } finally {
      if (!remove) {
        this.editGroupApplicationsDialog = false;
        await this.fetchGroups();
      }
    }
  }


  private openEditUsersDialog(group: Group) {
    this.currentGroup = {...group};
    this.editUsersDialog = true;
  }


  private async updateUsersInGroup(group: Group) {
    try {
      const currentUsers = group.users || []

      this.usersToKeep.push(...this.usersToAdd, ...currentUsers)
      const groupIdsToKeep = this.usersToKeep.map(user => user.id)
          .filter((id): id is number => id !== undefined);

      if (!group.id) {
        return;
      }
      await GroupService.updateUsersInGroup(group?.id, {
        userIds: groupIdsToKeep || [],
        tenantId: this.tenantStore.tenantId
      });
    } finally {
      this.usersToKeep = [];
      this.usersToAdd = [];
      this.editUsersDialog = false;
      await this.fetchGroups();
    }
  }

  private async updateUsersInGroups(usersToKeep: any) {
    try {
      await GroupService.updateUsersInGroups({
        usersToKeep: usersToKeep || []
      }, this.tenantStore.tenantId);
    } finally {
      this.editUsersDialog = false;
      for (const group of this.combinedGroups) {
        if (group?.id) {
          this.selectedUsers[group.id] = [];
        }
      }
      await this.fetchGroups();
    }
  }

  private resetDialog() {
    this.group = {
      name: '',
      userCount: 0
    };
    this.usersToKeep = [];
    this.usersToAdd = [];
    this.appsToKeep = [];
    this.appsToAdd = [];
  }

  private async removeUsersFromGroups() {
    if (Object.values(this.selectedUsers).some(arr => arr.length > 0)) {
      this.editUsersDialog = false;

      const usersToKeep = this.combinedGroups.flatMap(group => {
        if (!group?.id) {
          return;
        }
        const selectedUsersInGroup = this.selectedUsers[group.id] || [];

        return group.users?.filter(user => !selectedUsersInGroup.map(selected => selected.id).includes(user.id))
            .map(user => ({
              userId: user.id,
              groupId: group.id
            }));
      });

      await this.updateUsersInGroups(usersToKeep);
    }
  }

  private async removeUserFromGroup(user: User) {
    if (this.currentGroup?.users) {
      this.usersToKeep = this.currentGroup.users.filter((u) => u.id !== user.id) || [];
      this.currentGroup.users = this.currentGroup.users.filter((u) => u.id !== user.id) || [];
    }
  }

  private async removeApplicationAccessFromGroup(app: Application) {
    if (this.currentGroup?.applications) {
      this.appsToKeep = this.currentGroup.applications.filter((a) => a.id !== app.id) || [];
      this.currentGroup.applications = this.currentGroup.applications.filter((a) => a.id !== app.id) || [];
    }
  }

  private fullName(first: string, last: string): string {
    return `${first} ${last}`
  }

  private get disabled() {
    return !(this.group.name || this.currentGroup?.name);
  }

  private assignedApplications(apps: Application[]) {
    return apps.filter((app: Application) => app.belongsToAccount);
  }

  private unassignedApplications(apps: Application[]) {
    return apps.filter((app: Application) => !app.belongsToAccount);
  }
}
</script>

<template>
  <div>
    <v-row justify="space-between" align="center" dense>
      <v-col cols="12">
        <v-row align="center" dense>
          <v-col cols="12">
            <v-card color="#F4F4F4" variant="flat" class="pa-2" height="48">
              <v-row dense align="center">
                <v-col cols="6" md="8">
                  <v-row no-gutters align="center">
                    <v-col cols="4" md="auto">
                      <CreateGroup
                          :system="false"
                          :submit="createUserGroup"
                          :disabled="disabled"
                          :group="group"
                          :loading="loading"
                          :reset-dialog="resetDialog"
                          :removeUserFromGroup="removeUserFromGroup"
                          :removeApplicationAccessFromGroup="removeApplicationAccessFromGroup"
                          @fetchGroups="fetchGroups"
                      />
                    </v-col>
                    <v-col cols="4" md="auto">
                      <v-tooltip open-delay="200" location="bottom" v-if="selectedGroups.some((g) => !g.tenantId) && !userStore.isSuperAdmin" text="System groups can only be edited by super users">
                        <template v-slot:activator="{ props }">
                          <div v-bind="props">
                            <v-btn size="small" variant="plain" color="primary" @click="removeUserGroup()" disabled>
                              {{ mdAndUp ? 'Remove user group' : 'Remove' }}
                            </v-btn>
                          </div>
                        </template>
                      </v-tooltip>
                      <v-btn size="small" variant="plain" color="primary" @click="removeUserGroup()" v-else>
                        {{ mdAndUp ? 'Remove user group' : 'Remove' }}
                      </v-btn>
                    </v-col>
                    <v-col cols="4" md="auto">
                      <v-btn @click="removeUsersFromGroups" size="small" variant="plain" color="primary" :disabled="!Object.values(selectedUsers).some(arr => arr.length > 0)">
                       {{ mdAndUp ? 'Unassign users' : 'Remove' }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" md="4">
                  <v-row no-gutters>
                    <v-col cols="12" class="d-flex justify-end">
                      <v-text-field hide-details density="compact" placeholder="Search groups or users" append-inner-icon="mdi-magnify" v-model="searchNames" variant="underlined"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
        :items="combinedGroups"
        :headers="headers"
        return-object
        height="calc(100vh - 260px)"
        expand-on-click
        v-model="selectedGroups"
        :sort-by="[{key: 'name', order: 'asc'}]"
        items-per-page="-1"
        :expanded.sync="expandedGroups"
    >
      <template v-slot:[`header.data-table-expand`]>
        <v-btn icon variant="text" size="small" @click="expandedGroups = []" v-if="expandedGroups.length === combinedGroups.length"><v-icon>mdi-chevron-double-up</v-icon></v-btn>
        <v-btn icon variant="text" size="small" @click="expandedGroups = [...combinedGroups]" v-else><v-icon>mdi-chevron-double-down</v-icon></v-btn>
      </template>
      <template v-slot:[`item.users`]="{ item }">
        <v-chip color="primary" variant="flat" size="small" class="font-weight-bold">{{ userCount[item.id] || 0 }} user{{ userCount[item.id] > 1 || userCount[item.id] === 0 ? 's' : '' }} assigned</v-chip>
      </template>
      <template v-slot:[`item.access`]="{ item }">
        <v-chip color="grey" variant="tonal" size="small" class="font-weight-bold" v-if="!item.tenantId">System group</v-chip>
        <v-chip color="grey-darken-4" variant="tonal" size="small" class="font-weight-bold" v-else>User group</v-chip>
      </template>
      <template v-slot:[`item.applications`]="{ item }">
        <div class="d-flex align-center">
          <div v-for="app in item.applications" :key="app.id" class="mr-n2">
            <v-tooltip location="bottom" open-delay="200">
              <template v-slot:activator="{ props }">
                <v-avatar
                    class="font-weight-bold"
                    size="32"
                    style="border: 2px solid #ffffff"
                    color="primary"
                    icon
                    variant="flat"
                    @click.stop="currentGroup = {...item}; currentApplication = app;"
                    v-bind="props"
                >
                  <span v-if="!app.icon">{{ app.name?.charAt(0) }}</span>
                  <v-icon v-else color="white" size="18">mdi-{{ app.icon }}</v-icon>
                </v-avatar>
              </template>
              <template v-slot:default>
                {{ app.name }}
              </template>
            </v-tooltip>

          </div>

          <div v-if="item.outOfScopeApplicationCount" class="ml-2">
            <v-tooltip location="bottom" open-delay="200">
              <template v-slot:activator="{ props }">
                <div v-bind="props" :class="item.applications.length ? 'ml-2' : ''"><a class="link">({{ item.outOfScopeApplicationCount }} out of account scope)</a></div>
              </template>
              <template v-slot:default>
                This group has applications assigned that this account does not have access to
              </template>
            </v-tooltip>
          </div>
          <v-tooltip v-if="item.applications.length > 5" location="bottom" open-delay="200">
            <template v-slot:activator="{ props }">
              <div v-bind="props" class="ml-4"><a class="link">+ {{ item.applications.length - 5 }} more</a></div>
            </template>
            <template v-slot:default>
              <div v-for="app of item.applications.slice(5)" :key="app.id">{{ app.name }}</div>
            </template>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu>
          <template #activator="{ props }">
            <div class="d-flex justify-end">
              <v-btn v-bind="props" size="32" icon variant="flat" color="primary"><v-icon size="20">mdi-dots-vertical</v-icon></v-btn>
            </div>
          </template>
          <v-list density="compact">
            <v-list-item v-if="item.tenantId" @click="currentGroup = {...item}; editGroup(item);" prepend-icon="mdi-pencil" title="Edit group"></v-list-item>
            <v-list-item v-if="item.tenantId" @click="currentGroup = {...item}; editGroupApplicationsDialog = true;" prepend-icon="mdi-application-edit" title="Edit applications"></v-list-item>
            <v-list-item @click="openEditUsersDialog(item);" prepend-icon="mdi-account-multiple-plus" title="Edit users"></v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:expanded-row="{ columns, item }">
        <tr style="background-color: #f4f4f4">
          <td :colspan="columns.length" class="px-0">
            <v-card class="" variant="flat">
              <v-data-table
                  density="compact"
                  class="subTable"
                  :items="item.users"
                  :headers="subHeaders"
                  v-model="selectedUsers[item.id]"
                  return-object
                  show-select
              >
                <template v-slot:[`item.full_name`]="{ item }">
                  {{ fullName(item.first_name, item.last_name) }}
                </template>
                              <template v-slot:bottom></template>
              </v-data-table>
            </v-card>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog width="500" v-model="editGroupDialog">
      <template v-slot:default="{ isActive }">
        <v-card title="Edit system group">
          <v-card-text>
            <v-form>
              <v-row>
                <v-col cols="12">
                  <v-text-field hide-details density="compact" label="Group name" v-model="currentGroup.name"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                :loading="loading"
                :disabled="disabled"
                color="primary"
                @click="saveUserGroup(); isActive.value = false"
            >
              Save user group
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog width="500" v-model="editUsersDialog" @update:model-value="resetDialog">
      <template v-slot:default="{  }">
        <v-card title="And or remove users from the group">
          <v-card-text>
            <p class="mb-4">Add users to a group to give those users access to the applications available to that group.</p>
            <AssignUsers :currentGroup="currentGroup" @updateUsersToAdd="usersToAdd = $event" :removeUserFromGroup="removeUserFromGroup"/>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                @click="updateUsersInGroup(currentGroup)"
                :loading="loading"
                :disabled="loading"
            >
              Save group
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog width="500" v-model="editGroupApplicationsDialog" @afterLeave="resetDialog">
      <template v-slot:default>
        <v-card title="And or remove group applications">
          <v-card-text>
            <p class="mb-4">Add applications to a group to easily assign application access to a group of users.</p>
            <AssignApplications :currentGroup="currentGroup" :applications="applications" @updateAppsToAdd="appsToAdd = $event" :removeApplicationAccessFromGroup="removeApplicationAccessFromGroup"/>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                @click="updateApplicationAccessForGroup(currentGroup)"
                :loading="loading"
                :disabled="loading"
            >
              Save group
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<style>
.subTable {
  background-color: #f4f4f4  !important;
}

.v-data-table__tr--clickable:hover {
  background-color: #f4f4f4  !important;
}
.outline-card {
  border: thin solid rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}
</style>