import { appAxios } from "./app.axios";

export function getStage(): string {
  return process.env.VUE_APP_STAGE || 'local';
}

export function getAPIUrl(): string {
  return process.env.VUE_APP_API_URL || 'http://localhost:8089/api/v1.0';
}

export function getHelpUrl(): string | null {
  return process.env.VUE_APP_HELP_URL || null;
}

export async function downloadFile(url: string, defaultFileName: string): Promise<void> {
  await appAxios({
    url: url,
    method: 'GET',
    responseType: 'blob',
  }).then((res) => {
    const file = window.URL.createObjectURL(new Blob([res.data]));

    const docUrl: HTMLAnchorElement = document.createElement('a');
    docUrl.href = file;
    let fileName = defaultFileName;
    const contentDisposition = res.headers['content-disposition'];
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch?.length === 2) {
        fileName = fileNameMatch[1];
      }
    }
    docUrl.setAttribute('download', fileName);
    document.body.appendChild(docUrl);
    docUrl.click();
    docUrl.remove();
    window.URL.revokeObjectURL(url);
  });
}

export function getPublicContentURl(uri?: string) {
  let url: string = process.env.VUE_APP_PUBLIC_CONTENT_URL?.trim() || '';
  if (url.substring(url.length - 1) !== '/') {
    url += '/';
  }
  uri = uri?.trim() || '';
  if (uri.substring(0, 1) === '/') {
    uri = uri.substring(1);
  }
  return url + uri;
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}