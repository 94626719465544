<template>
  <v-row justify="center" align="center">
    <v-col cols="12" md="10" lg="6" class="pa-4">
      <v-card color="primary" class="pl-4 pr-6 py-6">
        <v-row no-gutters align="center">
          <v-col cols="auto">
            <v-btn @click="resent=false; error=''; $emit('go-to:email');" icon><v-icon color="primary">mdi-arrow-left</v-icon></v-btn>
          </v-col>
          <v-col class="text-center">
            <span class="text-white text-h5 font-weight-bold" v-if="!resent && !activating">Reset password</span>
            <span class="text-white text-h5 font-weight-bold" v-else-if="resent && !activating">Password reset</span>
            <span class="text-white text-h5 font-weight-bold" v-if="activating">Resend registration email</span>
          </v-col>
          <v-col cols="auto">
            <v-sheet width="36" height="36" color="transparent"></v-sheet>
          </v-col>
        </v-row>
        <v-row no-gutters justify="center">
          <v-col cols="12">
            <LoginError :error="error" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <span class="text-white text-body-1" v-if="!resent && !activating">If you've lost or want to reset your password, please confirm.</span>
            <span class="text-white text-body-1" v-if="!resent && activating">Click the button below to request a new registration code.</span>
            <span class="text-white text-body-1" v-if="resent">An email has been sent. {{!activating ? 'Please follow the instructions to reset your password.' : ''}}</span>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="relative d-flex justify-center">
            <ActionButton @click="resetPassword" text="Reset password" color="secondary" :loading="loading" v-if="!resent && !activating"></ActionButton>
            <ActionButton @click="resendActivationCode" text="Resend registration email" color="secondary" :loading="loading" v-if="!resent && activating"></ActionButton>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue, toNative, Prop } from "vue-facing-decorator";
import ActionButton from "@/components/ActionButton.vue";
import LoginService from "@/services/login.service";
import LoginError from "@/components/LoginError.vue";
@Component({
  components: {LoginError, ActionButton}
})
class ResendPrompt extends Vue {
  @Prop() private activating!: boolean;
  @Prop() private username!: string;
  private error = '';
  private loading = false;
  private resent = false;

  private async resetPassword() {
    try {
      this.loading = true;
      await LoginService.forgotPassword(this.username);
      this.resent = true;
    } catch (err) {
      this.error = (err as Error).message;
    } finally {
      this.loading = false;
    }
  }

  private async resendActivationCode() {
    this.loading = true;
    try {
      await LoginService.resendRegistrationCode(this.username);
      this.resent = true;
    } catch (err) {
      this.error = (err as Error).message;
    } finally {
      this.loading = false;
    }
  }
}
export default toNative(ResendPrompt);
</script>

<style scoped>
</style>