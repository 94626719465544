<script lang="ts">
import {Component, Vue, Watch} from 'vue-facing-decorator';
import {Application, Group, Role, User} from '@/types';
import {useDisplay} from 'vuetify';
import UserService from '@/services/user.service';
import {useUserStore} from '@/stores/userStore';
import ApplicationService from '@/services/application.service';
import {useTenantStore} from '@/stores/tenantStore';
import TenantService from '@/services/tenant.service';
import {useNotificationStore} from '@/stores/notificationStore';
import GroupService from "@/services/group.service";

@Component({})
export default class UserManagement extends Vue {
  private notificationStore = useNotificationStore();
  private tenantStore = useTenantStore();
  private userStore = useUserStore();
  private mdAndUp = useDisplay().mdAndUp;
  private searchNames = '';
  private users: User[] = [];
  private roles: Role[] = [];
  private selectedUsers: User[] = [];
  private applicationsToAdd: Application[] = [];
  private applicationRoleForUser: any = {};
  private applicationRoleContextForUser: any = {};
  private applicationRolesLookupData: { [appId: number]: { [role: string]: { items: { id: string | number, name: string }[], label: string, desc?: string } } } = {};
  private applicationsToRemove: Application[] = [];
  private loading = false;
  private editDialog = false;
  private editApplicationsDialog = false;
  private editGroupsDialog = false;
  private tab: 'details' | 'config' = 'details';
  private headers: Array<{ title: string; value: string; sortable: boolean; width?: string }> = [
    { title: 'Name', value: 'full_name', sortable: true },
    { title: 'Email', value: 'email', sortable: true },
    { title: 'Role', value: 'role', sortable: true },
    { title: 'Status', value: 'status', sortable: true },
    { title: 'Groups', value: 'groups', sortable: false },
    { title: 'Applications', value: 'applications', sortable: false },
    { title: '', value: 'actions', sortable: false },
  ];
  private user: { first_name: string; last_name: string; email: string; role?: number; applications: Application[] } = {
    first_name: '',
    last_name: '',
    email: '',
    role: undefined,
    applications: []
  }
  private currentUser: User | null = null;
  private currentGroup: Group | null = null;
  private newApp: Application | null = null;
  private newApps: Partial<Application>[] = [];
  private tenantApplications: Application[] = [];
  private currentApplication: Application | undefined | null = null;
  private attributes: {[key: string]: any} = {};
  private removeGroupDialog = false;
  private removeApplicationDialog = false;
  private warningDialog = false;
  private selectedRole: {[key: string]: any} = {};
  private appConfigDirtied = false;
  private searchTimeout: any = {};
  private applicationRoles: any = [];
  private selectedAppRole: {[key: number]: { name?: string | null, id?: string | number | null }} = {};
  private selectedAppRoleContext: {[key: number]: { name?: string | null, id?: string | number | null }} = {};
  private opened: any = [];
  private showRemoveDialog: {[key: number]: boolean} = {};
  private roleLookupDataLoading = false;

  private groupsToKeep: Group[] = [];
  private groupsToAdd: Group[] = [];
  private newGroups: Partial<Group>[] = [];
  private newGroup: Group | null = null;
  private combinedGroups: Group[] = [];

  private async mounted() {
    if (this.tenantStore.tenantId) {
      const [users, roles, tenantApplications] = await Promise.all([
        UserService.searchUsers(this.tenantStore.tenantId),
        UserService.getRoles(),
        ApplicationService.getApplicationsForTenant(this.tenantStore.tenantId),
        this.fetchGroups()
      ]);

      this.users = users;
      this.roles = roles;
      this.tenantApplications = tenantApplications;
    }
  }

  private get mappedRoles() {
    return this.roles.map(({ id, name, slug, context, admin }) => {
      return { id, name: name.toLowerCase() === 'user' ? 'Application Consumer' : name, slug, context, admin }
    })
  }

  private get filteredTenantApplications() {
    return this.tenantApplications.filter((ta) => !this.currentUser?.applications?.map((cua) => cua.id).includes(ta.id) && !this.applicationsToAdd.map((ata) => ata.id).includes(ta.id))
  }

  private get userApplications() {
    return this.tenantApplications.filter((ta) => this.currentUser?.applications?.map((cua) => cua.id).includes(ta.id));
  }

  private async mapConfig(app: Application) {
    this.currentApplication = this.currentUser?.applications?.find((a) => a.id === app.id);
    const currentUserApplicationRole = this.currentUser?.application_roles?.find((r) => r.application_id === app.id);
    this.selectedAppRole[app.id] = {name: currentUserApplicationRole?.role, id: currentUserApplicationRole?.role};
    if (this.currentApplication && currentUserApplicationRole?.role) {
      await this.getRoleLookupData(this.currentApplication, currentUserApplicationRole?.role);
    }
    this.applicationRoleForUser[app.id] = currentUserApplicationRole?.role;
    this.applicationRoleContextForUser[app.id] = currentUserApplicationRole?.roleContext;
    const roleContext = this.applicationRolesLookupData[app.id]?.[this.applicationRoleForUser[app.id]];
    const selectedRoleContext = roleContext?.items?.find((i) => i.id === currentUserApplicationRole?.roleContext);
    this.selectedAppRoleContext[app.id] = {name: selectedRoleContext?.name, id: selectedRoleContext?.id};
    const attrs = this.getApplicationAttributes(app);
    for (const attr of attrs) {
      if (attr.default === 'true') {
        attr.default = true;
      }
      this.roleAttributes(attr);
    }
  }

  private async getRoleLookupData(app: Application, role: string): Promise<{ items: { id: string | number, name: string }[], label: string, desc?: string } | undefined> {
    this.roleLookupDataLoading = true;
    try {
      const config = this.getApplicationRoles(app).find((a: any) => a.id === role);
      if (!config?.lookup) {
        return;
      }
      let roleLookupResult = this.applicationRolesLookupData[app.id]?.[role];
      if (!roleLookupResult) {
        if (!this.applicationRolesLookupData[app.id]) {
          this.applicationRolesLookupData[app.id] = {};
        }
        roleLookupResult = this.applicationRolesLookupData[app.id][role] = {
          items: [],
          label: 'Unknown',
          desc: undefined
        };
        roleLookupResult.label = config.lookup.name;
        if (config.lookup.description) {
          roleLookupResult.desc = config.lookup.description;
        }
        const lookupData = await ApplicationService.makeExternalApplicationAPICall(this.tenantStore.tenantId!, app.id, config.lookup.url);
        if (lookupData?.length) {
          roleLookupResult.items = lookupData.map((d: any) => ({
            id: d[config.lookup.identityField],
            name: d[config.lookup.displayField]
          }));
        }
      }
      return roleLookupResult;
    } finally {
      this.roleLookupDataLoading = false;
    }
  }

  private getApplicationRoles(app: Application) {
    return app?.details?.users?.roles || [];
  }

  private roleAttributes(attribute: any) {
    if (this.currentApplication || this.newApp) {
      const roleForCurrentApp = this.currentUser?.application_roles?.find((r) => (r.application_id === this.currentApplication?.id) || (r.application_id === this.newApp?.id));

      let value;
      if (roleForCurrentApp?.attributes?.length) {
        if (attribute) {
          const currentAttribute = roleForCurrentApp?.attributes.find((a: any) => a.slug === attribute.id);
          if (currentAttribute) {
            value = typeof currentAttribute.value === 'string' ? currentAttribute.value === 'true' : currentAttribute.value;
          } else {
            value = attribute.default;
          }
        }
      } else {
        value = attribute.default;
      }
      this.attributes[attribute.id] = value;

      this.currentApplication ? this.applicationRoleForUser[this.currentApplication.id] = roleForCurrentApp?.role :
        this.newApp ? this.applicationRoleForUser[this.newApp.id] = roleForCurrentApp?.role : null;
    }
  }

  private getApplicationAttributes(app?: Application | null) {
    return app?.details?.users?.attributes.map((a: { id: string; name: string; description: string; default: string }) => {
      return {
        ...a,
        default: a.default
      }
    }) || [];
  }

  private async fetchUsers(take = 100, skip = 0, sort = 'users.first_name', desc = false, filters?: any, searchString?: string) {
    if (this.tenantStore.tenantId) {
      this.users = await UserService.searchUsers(this.tenantStore.tenantId, take, skip, sort, desc, filters, searchString);
    }
  }

  private async fetchGroups() {
    if (this.tenantStore?.tenantId) {
      this.combinedGroups = await GroupService.getAllGroups(this.tenantStore.tenantId)
    }
  }

  private async updateTableOptions(opts: any) {
    let sort = opts[0]?.key || 'full_name';
    if (sort === 'full_name') {
      sort = 'first_name';
    }
    await this.fetchUsers(100, 0, `users.${sort}`, opts[0]?.order === 'desc', undefined)
  }

  @Watch('searchNames')
  private async search(string: string) {
    const mappedString = ('application consumer').includes(string.toLowerCase()) ? 'user' : string;
    this.searchDebounced(mappedString)
  }

  private searchDebounced(searchString: string) {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(async () => {
      await this.fetchUsers(100, 0, '', false, undefined, searchString);
    }, 500)
  }

  private async addUser() {
    if (this.tenantStore.tenantId) {
      this.loading = true;
      try {
        await UserService.addUser(this.tenantStore.tenantId, {user: this.user, roles: [this.user.role!]})
      } finally {
        this.user = {
          first_name: '',
          last_name: '',
          email: '',
          role: undefined,
          applications: []
        };
        await this.fetchUsers()
        this.loading = false;
      }
    }
  }


  private async removeUser() {
    this.loading = true
    if (this.selectedUsers.length && this.tenantStore.tenantId) {
      for (const user of this.selectedUsers) {
        if (user.id) {
          await UserService.removeUser(this.tenantStore.tenantId, user.id);
        }
      }
      this.users = await UserService.searchUsers(this.tenantStore.tenantId);
      this.selectedUsers = [];
    }
    this.loading = false;
  }


  private addApplicationRow(user: User | null) {
    if (user?.id) {
      this.newApps.push({ name: '', type: '' });
    }
  }

  private async addApplicationAccess() {
    if (this.newApp && this.currentUser) {
      this.applicationsToAdd.push(this.newApp);
      this.opened.push(this.applicationsToAdd.findIndex((a) => a.id === this.newApp?.id))
      this.currentUser.application_roles?.push({ attributes: null, application_id: this.newApp.id, role: null, roleContext: null });

      this.attributes = this.newApp.details?.users?.attributes?.reduce((acc: any, item: any) => {
        acc[item.id] = {
          slug: item.id,
          value: item.default === "true"
        };
        return acc;
      }, {}) || {};

      await this.mapConfig(this.newApp);
      this.newApp = null;
      this.newApps = [];
    }
  }

  private removeNewApplication(app: Application) {
    this.applicationsToAdd = this.applicationsToAdd.filter((a) => a.id !== app.id);
    //this.selectedAppRole[app.id] = {};
    //this.applicationRoleForUser[app.id] = null;
    //this.selectedAppRoleContext[app.id] = {};
    //this.applicationRoleContextForUser[app.id] = null;
  }

  private async removeApplication(app: Application, saveUser = false) {
    this.loading = true;
    try {
      this.applicationsToRemove.push(app);
      if (this.currentUser) {
        this.currentUser.applications = this.currentUser?.applications?.filter((a) => a.id !== app.id);
        //this.selectedAppRole[app.id] = {};
        //this.applicationRoleForUser[app.id] = null;
        //this.selectedAppRoleContext[app.id] = {};
        //this.applicationRoleContextForUser[app.id] = null;
        this.appConfigDirtied = true;
      }

      if (saveUser) {
        await this.saveUser()
      }
    } finally {
      this.appConfigDirtied = true;
      this.loading = false;
    }
  }

  private removeNewGroup(group: Group) {
    this.groupsToAdd = this.groupsToAdd.filter((g) => g.id !== group.id);
  }

  private addGroupRow() {
    this.newGroups.push({ name: '', userCount: 0 });
  }


  private addAllGroups(user: User | null) {
    if (user) {
      user.groups = [];
      this.newGroups = [];
      this.groupsToAdd = this.combinedGroups;
    }
  }

  private removeAllGroups(user: User | null) {
    if (user) {
      user.groups = [];
      this.newGroups = [];
      this.groupsToAdd = [];
    }
  }

  private get filteredGroups() {
    return this.combinedGroups.filter((group: Group) => {
      const addingGroup = this.groupsToAdd.map((g) => g.id).includes(group.id);
      const hasGroup = this.currentUser?.groups?.map((g: Group) => g.id).includes(group.id) || false;

      return !addingGroup && !hasGroup;
    });
  }

  private initials(first: string, last: string): string {
    return `${first.charAt(0)} ${last.charAt(0)}`
  }

  private fullName(first: string, last: string): string {
    return `${first} ${last}`
  }

  private async saveUser() {
    this.loading = true;
    try {
      if (this.tenantStore.tenantId && (this.currentUser && this.currentUser.id)) {
        if (this.currentUser && this.currentUser.applications) {
          const apps = [...this.currentUser.applications.filter((a) => a), ...this.applicationsToAdd];
          const applicationIds = apps.map((a) => a.id).filter((a) => !this.applicationsToRemove.map((r) => r.id).includes(a));
          const ids = Array.from(new Set([...applicationIds]));
          // let applications: any[] = [];
          // ids.forEach((id) => {
          //   this.currentApplication = apps.find((a) => a.id === id);
          //   applications.push({
          //     application_id: id,
          //     role: this.applicationRoleForUser[id],
          //     roleContext: this.applicationRoleContextForUser[id] || null,
          //     attributes: this.getApplicationAttributes(this.currentApplication).map((a: Application) => {
          //       return {
          //         slug: a.id,
          //         value: this.attributes[a.id]
          //       }
          //     })
          //   })
          // });
          //
          // if (this.selectedRole.id && !this.currentUser.roles?.map((r) => r.id).includes(this.selectedRole.id)) {
          //   await UserService.updateUser(this.tenantStore.tenantId, this.currentUser.id, { applications: applications || [], role: this.selectedRole.id });
          // }
          // await TenantService.updateUserRoleAndApplicationAccess(this.tenantStore.tenantId, Number(this.currentUser?.id), applications);

          await UserService.updateUserAppAccess(this.currentUser.id, this.tenantStore.tenantId, { applicationIds: ids, role: this.selectedRole.id })

          if (this.currentUser.id === this.userStore.user.id) {
            await this.userStore.refreshUser();
          }
        }
      }
    } catch (e) {
      const err = e as Error;
      this.notificationStore.addToast(err)
      console.error(err)
    } finally {
      await this.fetchUsers();
      this.applicationsToAdd = [];
      this.applicationsToRemove = [];
      this.loading = false;
      this.editDialog = false;
      this.editApplicationsDialog = false;
      this.tab = 'details'
    }
  }

  private resetDialog() {
    this.appConfigDirtied = false;
    this.user = {
      first_name: '',
      last_name: '',
      email: '',
      role: undefined,
      applications: []
    };
  }

  private async editUser(item: any) {
    this.loading = true;
    try {
      this.currentUser = {...item};
      const jobs = this.currentUser?.applications?.length ? this.currentUser.applications.map((a) => this.mapConfig(a)) : [];
      await Promise.all(jobs);
      this.editDialog = true;
      const selectedRole = {...this.currentUser?.roles?.[0]};
      if (selectedRole?.name?.toLowerCase() === 'user') {
        selectedRole.name = 'Application Consumer'
      }
      this.selectedRole = selectedRole;
    } finally {
      this.loading = false;
    }
  }

  private async editUserApplications(item: any) {
    this.loading = true;
    try {
      this.currentUser = {...item};
      const jobs = this.currentUser?.applications?.length ? this.currentUser.applications.map((a) => this.mapConfig(a)) : [];
      await Promise.all(jobs);
      this.editApplicationsDialog = true;
      const selectedRole = {...this.currentUser?.roles?.[0]};
      if (selectedRole?.name?.toLowerCase() === 'user') {
        selectedRole.name = 'Application Consumer'
      }
      this.selectedRole = selectedRole;
    } finally {
      this.loading = false;
    }
  }

  private openEditGroupsDialog(item: any) {
    this.currentUser = {...item};
    this.editGroupsDialog = true;
  }

  private removeGroupFromUser(group: any) {
    this.groupsToKeep = this.currentUser?.groups.filter((g) => g.id !== group.id) || [];
    if (this.currentUser) {
      this.currentUser.groups = this.currentUser?.groups.filter((g) => g.id !== group.id) || [];
    }
  }

  private addGroup() {
    try {
      if (this.newGroup && this.currentUser) {
        this.groupsToAdd = this.groupsToAdd || []
        this.groupsToAdd.push(this.newGroup);
        this.newGroup = null;
        this.newGroups = [];
      }
    } finally {
      //
    }
  }

  private async saveGroups(user: User, remove = false) {
    try {
      if (user?.id) {

        const currentGroups = user.groups || []
        const groups = [...this.groupsToAdd, ...currentGroups];

        const groupIdsToKeep = groups.map(group => group.id).filter((g): g is number => g !== undefined)
        await GroupService.updateUserGroups(user.id, {groupIds: groupIdsToKeep}, this.tenantStore.tenantId);
      }
    } finally {
      if (!remove) {
        this.editGroupsDialog = false;
        await this.fetchUsers();
      }
    }
  }


  private hasRoleAssigned() {
    for (const app of [...(this.applicationsToAdd || []), ...(this.currentUser?.applications || [])]) {
      const roles = this.getApplicationRoles(app);
      if (roles.length) {
        const appRole = this.selectedAppRole[app.id];
        if (!appRole?.id) {
          return false;
        } else if (roles.find((r: any) => appRole.id === r.id)?.lookup && !this.selectedAppRoleContext[app.id]?.id) {
          return false;
        }
      }
    }
    return true;
  }

  private async removeUserFromGroup(group: Group | null, remove = false) {
    const currGroup = this.currentGroup ? this.currentGroup : group;
    if (currGroup?.id && this.currentUser?.id) {
      try {
        await GroupService.removeUserFromGroup(currGroup.id, this.currentUser.id, this.tenantStore.tenantId)
      } finally {
        if (remove) {
          await this.fetchUsers()
        }
      }
    }
  }

  private roleProps(item: any) {
    return {
      title: item.name,
      subtitle: item.description
    }
  }

  private roleContextProps(item: any) {
    return {
      title: item.name,
      subtitle: item.description
    }
  }
}
</script>

<template>

  <div>
    <v-row justify="space-between" align="center" dense>
      <v-col cols="12">
        <v-row align="center" dense>
          <v-col cols="12">
            <v-card color="#F4F4F4" variant="flat" class="pa-2" height="48">
              <v-row dense align="center">
                <v-col cols="6" md="8">
                  <v-row no-gutters align="center">
                    <v-col cols="4" md="auto">
                      <v-dialog width="500" @update:modelValue="resetDialog()">
                        <template v-slot:activator="{ props }">
                          <v-btn v-bind="props" size="small" variant="plain" color="primary">
                            {{ mdAndUp ? 'Add user' : 'Add' }}
                          </v-btn>
                        </template>

                        <template v-slot:default="{ isActive }">
                          <v-card title="Add a user">
                            <v-card-text>
                              <v-form>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-text-field hide-details density="comfortable" label="First name" v-model="user.first_name" @update:model-value="appConfigDirtied = true"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field hide-details density="comfortable" label="Last name" v-model="user.last_name" @update:model-value="appConfigDirtied = true"></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field hide-details density="comfortable" label="Email" v-model="user.email" @update:model-value="appConfigDirtied = true" type="email"></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-select hide-details density="comfortable" label="Role" :items="mappedRoles" item-title="name" item-value="id" v-model="user.role" @update:model-value="appConfigDirtied = true"></v-select>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>

                              <v-btn
                                  :loading="loading"
                                  :disabled="!user.first_name || !user.last_name || !user.email || !user.role || loading"
                                  color="primary"
                                  @click="addUser(); isActive.value = false;"
                              >
                                Add user
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-col>
                    <v-col cols="4" md="auto">
                      <v-btn size="small" variant="plain" :disabled="!selectedUsers.length" color="primary" @click="removeUser">
                        {{ mdAndUp ? 'Remove user' : 'Remove' }}
                      </v-btn>
                    </v-col>
                    <!--                <v-col cols="4" md="auto">-->
                    <!--                  <v-btn size="small" variant="plain" color="primary">-->
                    <!--                    {{ mdAndUp ? 'Bulk add users' : 'Bulk add' }}-->
                    <!--                  </v-btn>-->
                    <!--                </v-col>-->
                  </v-row>
                </v-col>
                <v-col cols="6" md="4">
                  <v-row no-gutters>
                    <v-col cols="12" class="d-flex justify-end">
                      <v-text-field hide-details variant="underlined" density="compact" placeholder="Search users" append-inner-icon="mdi-magnify" v-model="searchNames"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
    <v-data-table
      :sort-by="[{key: 'name', order: 'asc'}]"
      :items="users"
      :headers="headers"
      v-model="selectedUsers"
      fixed-header
      show-select
      return-object
      height="calc(100vh - 260px)"
      @update:sort-by="updateTableOptions"
      items-per-page="25"
    >
      <template v-slot:[`item.full_name`]="{ item }">
        {{ fullName(item.first_name, item.last_name) }}
      </template>
      <template v-slot:[`item.role`]="{ item }">
        <!--TODO: Actually fetch role for this user in tenant-->
        {{ item.roles.map((r) => r.id).includes(1) ? 'Manager' : item.roles.map((r) => r.id).includes(2) ? 'Application Consumer' : 'Unknown' }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div v-if="item.status === 'active'" class="d-flex">
          <v-icon start icon="mdi-circle" color="success"></v-icon>
          Active
        </div>
        <div v-if="item.status === 'pending'" class="d-flex">
          <v-icon start icon="mdi-circle" color="grey"></v-icon>
          Pending
        </div>
      </template>
      <template v-slot:[`item.groups`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex" v-for="(group, index) of item.groups" :key="group.id">
            <div class="mr-n2">
              <v-tooltip :text="group.name" location="bottom" open-delay="200" v-if="index < 5">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" size="32" style="border: 2px solid #ffffff" icon variant="flat" color="primary" @click.stop="currentUser = {...item}; currentGroup = group; removeGroupDialog = true;">
                    {{ group.name?.charAt(0) }}
                  </v-btn>
                </template>
              </v-tooltip>
            </div>
          </div>
          <v-tooltip v-if="item.groups.length > 5" location="bottom" open-delay="200">
            <template v-slot:activator="{ props }">
              <div v-bind="props" class="ml-4"><a class="link">+ {{ item.groups.length - 5 }} more</a></div>
            </template>
            <template v-slot:default>
              <div v-for="group of item.groups.slice(5)" :key="group.id">{{ group.name }}</div>
            </template>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.applications`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex" v-for="(app, index) of item.applications" :key="app.id">
            <div class="mr-n2">
              <v-tooltip :text="app.name" location="bottom" open-delay="200" v-if="index < 5">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" size="32" style="border: 2px solid #ffffff" icon variant="flat" color="primary" @click.stop="currentUser = {...item}; currentApplication = app; removeApplicationDialog = true;">
                    <span v-if="!app.icon">{{ app.name?.charAt(0) }}</span>
                    <v-icon v-else color="white" size="18">mdi-{{ app.icon }}</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </div>
          </div>
          <v-tooltip v-if="item.applications.length > 5" location="bottom" open-delay="200">
            <template v-slot:activator="{ props }">
              <div v-bind="props" class="ml-4"><a class="link">+ {{ item.applications.length - 5 }} more</a></div>
            </template>
            <template v-slot:default>
              <div v-for="app of item.applications.slice(5)" :key="app.id">{{ app.name }}</div>
            </template>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }"><v-menu>
        <template #activator="{ props }">
          <div class="d-flex justify-end">
            <v-btn v-bind="props" size="32" icon variant="flat" color="primary"><v-icon size="20">mdi-dots-vertical</v-icon></v-btn>
          </div>
        </template>
        <v-list density="compact">
          <v-list-item @click="editUser(item)" prepend-icon="mdi-account-edit" title="Edit user"></v-list-item>
          <v-list-item @click="editUserApplications(item)" prepend-icon="mdi-application-edit" title="Edit applications"></v-list-item>
          <v-list-item @click="openEditGroupsDialog(item)" prepend-icon="mdi-group" title="Edit groups"></v-list-item>
        </v-list>
      </v-menu>
      </template>
    </v-data-table>
    <v-dialog width="500" v-model="removeApplicationDialog">
      <template v-slot:default="{ isActive }">
        <v-card>
          <v-card-title>Remove {{ currentApplication.name }} from {{ currentUser.first_name }} {{ currentUser.last_name }}?</v-card-title>
          <v-card-text>
            Are you sure you want to remove this application from this user? The user will no longer have access to the application.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                @click="isActive.value = false">
              Cancel
            </v-btn>
            <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                @click="removeApplication(currentApplication, true); isActive.value = false">
              Remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog width="auto" max-width="600" v-model="removeGroupDialog">
      <template v-slot:default="{ isActive }">
        <v-card>
          <v-card-title>Remove {{ currentUser.first_name }} {{ currentUser.last_name }} from {{ currentGroup.name }}?</v-card-title>
          <v-card-text>
            Are you sure you want to remove this group from this user? The user will no longer have access to the applications assigned to this group.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                @click="isActive.value = false">
              Cancel
            </v-btn>
            <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                @click="removeUserFromGroup(currentApplication, true); isActive.value = false">
              Remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog width="500" v-model="editDialog" id="edit-dialog" @update:modelValue="applicationsToAdd = []; newApps = []; tab = 'details'; appConfigDirtied = false;">
      <template v-slot:default>
        <v-card title="Edit user details">
          <v-card-text>
            <v-form>
              <v-row align="center">
                <v-col cols="12" class="pb-0">
                  <v-text-field label="Name" hide-details density="comfortable" readonly :model-value="fullName(currentUser.first_name, currentUser.last_name)"></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field label="Email" hide-details density="comfortable" readonly :model-value="currentUser.email" type="email"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select @update:model-value="appConfigDirtied = true; selectedRole = $event" hide-details density="comfortable" label="Role" :items="mappedRoles" item-title="name" item-value="id" return-object :model-value="selectedRole"></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              :disabled="loading || !appConfigDirtied || !hasRoleAssigned()"
              color="primary"
              @click="saveUser(); appConfigDirtied = false;"
            >
              Save user
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog width="500" v-model="editGroupsDialog" @afterLeave="groupsToAdd = []; newGroups = []; groupsToKeep = []; currentUser = null; currentGroup = null">
      <template v-slot:default>
        <v-card title="Edit user groups">
          <v-card-text>
            <v-form>
              <v-row align="center">
                <v-col cols="12" class="pb-0">
                  <v-btn block rounded="pill" color="primary" variant="flat" @click="addGroupRow(currentUser)" :disabled="!filteredGroups.length">Add group</v-btn>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-btn block rounded="pill" color="primary" variant="flat" @click="addAllGroups(currentUser)" :disabled="!filteredGroups.length">Add all groups</v-btn>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-btn block rounded="pill" color="primary" variant="flat" @click="removeAllGroups(currentUser)">Remove all groups</v-btn>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-list>
                    <v-list-item v-for="group of currentUser?.groups" :key="group.name" style="background-color: rgba(0,0,0,0.05)" rounded class="my-2">
                      <v-row no-gutters class="flex-nowrap" justify="space-between" align="center">
                        <v-col cols="11">
                          <v-list-item-title>{{ group.name }}</v-list-item-title>
                        </v-col>
                        <v-col cols="1">
                          <v-list-item-action>
                            <v-dialog :attach="true">
                              <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" icon variant="flat" color="transparent" size="32"><v-icon icon="mdi-delete" color="primary" size="24"></v-icon></v-btn>
                              </template>
                              <template v-slot:default="{ isActive }">
                                <v-card max-width="460">
                                  <v-card-title>Remove user from group</v-card-title>
                                  <v-card-text>
                                    Are you sure you want to remove this user from this group? The user will lose access to the applications assigned to the group.
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="isActive.value = false">
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="removeGroupFromUser(group); isActive.value = false">
                                      Remove
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </template>
                            </v-dialog>
                          </v-list-item-action>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item v-for="group of groupsToAdd" :key="group.name" style="background-color: rgba(0,0,0,0.05)" rounded class="my-2">
                      <v-row no-gutters justify="space-between" align="center">
                        <v-col cols="11" class="align-content-center">
                          <v-list-item-title>{{ group.name }}</v-list-item-title>
                        </v-col>
                        <v-col cols="1">
                          <v-list-item-action>
                            <v-btn icon variant="flat" color="transparent" size="32" @click="removeNewGroup(group)"><v-icon icon="mdi-delete" color="primary" size="24"></v-icon></v-btn>
                          </v-list-item-action>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item
                        class="mb-1 px-0 pt-0"
                        v-for="group of newGroups"
                        :key="group.name"
                    >
                      <v-combobox
                          class="app-combobox"
                          clearable
                          :items="filteredGroups"
                          item-title="name"
                          hide-details
                          density="comfortable"
                          placeholder="Search groups"
                          return-object
                          :menu-props="{ maxWidth: 452 }"
                      >
                        <template v-slot:selection="{ item }">
                          <div class="d-flex flex-column">
                            <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                          </div>
                        </template>
                        <template v-slot:item="{ item }">
                          <div class="d-flex flex-column">
                            <v-list-item @click="newGroup = item.raw; addGroup()">
                              <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                            </v-list-item>
                          </div>
                        </template>
                      </v-combobox>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="primary"
              @click="saveGroups(currentUser)"
            >
              Save user
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog width="500" v-model="editApplicationsDialog" id="edit-dialog" @update:modelValue="applicationsToAdd = []; newApps = []; tab = 'details'; appConfigDirtied = false;">
      <template v-slot:default>
        <v-card>
          <v-card-title>Edit {{ currentUser.first_name }} {{ currentUser.last_name }} applications</v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" class="pb-0">
                <v-btn block rounded="pill" color="primary" variant="flat" @click="addApplicationRow(currentUser); appConfigDirtied = true;"> Add application </v-btn>
              </v-col>
              <v-col cols="12">
                <v-list v-for="app of currentUser.applications" :key="app.name" lines="one" class="py-1" density="compact">
                  <template v-if="currentUser && currentUser.applications?.length && currentUser.applications.every((app) => app.id)">
                    <v-list-item style="background-color: rgba(0,0,0,0.05)" rounded class="pa-0">
                      <v-expansion-panels @update:model-value="mapConfig(app);">
                        <v-expansion-panel bg-color="transparent" elevation="0">
                          <v-expansion-panel-title class="py-0 px-2" static hide-actions>
                            <template v-slot:default="{ collapseIcon, expandIcon, expanded }">
                              <v-row no-gutters justify="space-between" align="center">
                                <v-col cols="1">
                                  <span v-if="expanded"><v-icon>{{ collapseIcon }}</v-icon></span>
                                  <span v-else><v-icon>{{ expandIcon }}</v-icon></span>
                                </v-col>
                                <v-col cols="10">
                                  <v-list-item-title>{{ app.name }}</v-list-item-title>
                                  <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
                                </v-col>
                                <v-col cols="1">
                                  <v-list-item-action>
                                    <v-btn @click.stop="showRemoveDialog[app.id] = true" icon variant="flat" color="transparent" size="32"><v-icon color="primary" icon="mdi-delete" size="24"></v-icon></v-btn>
                                  </v-list-item-action>
                                </v-col>
                              </v-row>
                            </template>
                          </v-expansion-panel-title>
                          <v-expansion-panel-text class="mt-1">
                            <v-row>
                              <v-col cols="12" v-if="getApplicationRoles(app).length">
                                <p class="text-disabled mb-4">Assign a role to this user for this application</p>
                                <v-row>
                                  <v-select
                                    class="mx-2"
                                    label="Application role"
                                    :items="getApplicationRoles(app)"
                                    v-model="selectedAppRole[app.id]"
                                    density="comfortable"
                                    @update:model-value="appConfigDirtied = true; applicationRoleForUser[app.id] = selectedAppRole[app.id].id; applicationRoleContextForUser[app.id] = null; selectedAppRoleContext[app.id] = {}; getRoleLookupData(app, applicationRoleForUser[app.id]);"
                                    hide-details
                                    :item-props="roleProps"
                                  >
                                    <template #selection="{ item }">
                                      <span class="text-capitalize">{{ item.value.name }}</span>
                                    </template>
                                  </v-select>
                                </v-row>
                                <v-row v-if="applicationRolesLookupData[app.id]?.[applicationRoleForUser[app.id]]">
                                  <v-select
                                    class="mx-2"
                                    :label="applicationRolesLookupData[app.id][applicationRoleForUser[app.id]].label"
                                    :items="applicationRolesLookupData[app.id][applicationRoleForUser[app.id]].items"
                                    v-model="selectedAppRoleContext[app.id]"
                                    density="comfortable"
                                    @update:model-value="appConfigDirtied = true; applicationRoleContextForUser[app.id] = selectedAppRoleContext[app.id].id;"
                                    hide-details
                                    :item-props="roleContextProps"
                                    :loading="roleLookupDataLoading"
                                  >
                                    <template #selection="{ item }">
                                      <span class="text-capitalize">{{ item.value.name }}</span>
                                    </template>
                                  </v-select>
                                </v-row>
                              </v-col>
                              <v-col cols="12" v-else>
                                <p class="text-disabled">This application does not have any roles</p>
                              </v-col>
                              <v-col cols="12" v-if="getApplicationAttributes(app).length">
                                <p class="text-disabled mb-4">Configure application attributes for this user</p>
                                <v-row class="ml-2">
                                  <v-col cols="12" v-for="attribute of getApplicationAttributes(app)" :key="attribute.id">
                                    <v-row no-gutters justify="space-between">
                                      <v-col>
                                        <v-list-item-title>{{ attribute.name }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ attribute.description }}</v-list-item-subtitle>
                                      </v-col>
                                      <v-col cols="2">
                                        <v-switch hide-details v-if="attribute.type === 'boolean'" density="compact" v-model="attributes[attribute.id]" @update:model-value="appConfigDirtied = true" color="primary"></v-switch>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-text>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-list-item>
                  </template>
                  <v-dialog :attach="true" v-model="showRemoveDialog[app.id]">
                    <template v-slot:default="{ isActive }">
                      <v-card max-width="460" min-height="200">
                        <v-card-title>Remove application from user</v-card-title>
                        <v-card-text>
                          Are you sure you want to remove this application from this user? The user will no longer have access to the application.
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            @click="isActive.value = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            :loading="loading"
                            :disabled="loading"
                            color="primary"
                            @click="removeApplication(app); isActive.value = false">
                            Remove
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-list>
                <v-list class="pt-1">
                  <v-list-item v-for="app of applicationsToAdd" :key="app.name"  rounded class="pa-0 mb-4">
                    <v-expansion-panels :model-value="opened">
                      <v-expansion-panel bg-color="rgba(0,0,0,0.05)">
                        <v-expansion-panel-title class="py-0 px-2" static hide-actions>
                          <template v-slot:default="{ collapseIcon, expandIcon, expanded }">
                            <v-row no-gutters justify="space-between" align="center">
                              <v-col cols="1">
                                <span v-if="expanded"><v-icon>{{ collapseIcon }}</v-icon></span>
                                <span v-else><v-icon>{{ expandIcon }}</v-icon></span>
                              </v-col>
                              <v-col cols="10">
                                <v-list-item-title>{{ app.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
                              </v-col>

                              <v-col cols="1">
                                <v-list-item-action>
                                  <v-btn @click.stop="removeNewApplication(app)" icon variant="flat" color="transparent" size="32"><v-icon color="primary" icon="mdi-delete" size="24"></v-icon></v-btn>
                                </v-list-item-action>
                              </v-col>
                            </v-row>
                          </template>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="mt-1">
                          <v-row>
                            <v-col cols="12" v-if="getApplicationRoles(app).length">
                              <p class="text-disabled mb-4">Assign a role to this user for this application</p>
                              <v-row>
                                <v-select
                                  class="mx-2"
                                  label="Application role"
                                  :items="getApplicationRoles(app)"
                                  v-model="selectedAppRole[app.id]"
                                  density="comfortable"
                                  @update:model-value="appConfigDirtied = true; applicationRoleForUser[app.id] = selectedAppRole[app.id].id; applicationRoleContextForUser[app.id] = null; selectedAppRoleContext[app.id] = {}; getRoleLookupData(app, applicationRoleForUser[app.id]);"
                                  hide-details
                                  :item-props="roleProps"
                                >
                                  <template #selection="{ item }">
                                    <span class="text-capitalize">{{ item.value.name }}</span>
                                  </template>
                                </v-select>
                              </v-row>
                              <v-row v-if="applicationRolesLookupData[app.id]?.[applicationRoleForUser[app.id]]">
                                <v-select
                                  class="mx-2"
                                  :label="applicationRolesLookupData[app.id][applicationRoleForUser[app.id]].label"
                                  :items="applicationRolesLookupData[app.id][applicationRoleForUser[app.id]].items"
                                  v-model="selectedAppRoleContext[app.id]"
                                  density="comfortable"
                                  @update:model-value="appConfigDirtied = true; applicationRoleContextForUser[app.id] = selectedAppRoleContext[app.id].id;"
                                  hide-details
                                  :item-props="roleContextProps"
                                  :loading="roleLookupDataLoading"
                                >
                                  <template #selection="{ item }">
                                    <span class="text-capitalize">{{ item.value.name }}</span>
                                  </template>
                                </v-select>
                              </v-row>

                            </v-col>
                            <v-col cols="12" v-else>
                              <v-list-item-title>This application does not have any roles</v-list-item-title>
                            </v-col>
                            <v-col cols="12" v-if="getApplicationAttributes(app).length">
                              <p class="text-disabled mb-4">Configure application attributes for this user</p>
                              <v-row class="ml-2">
                                <v-col cols="12" v-for="attribute of getApplicationAttributes(app)" :key="attribute.id">
                                  <v-row no-gutters justify="space-between">
                                    <v-col>
                                      <v-list-item-title>{{ attribute.name }}</v-list-item-title>
                                      <v-list-item-subtitle>{{ attribute.description }}</v-list-item-subtitle>
                                    </v-col>
                                    <v-col cols="2">
                                      <v-switch hide-details v-if="attribute.type === 'boolean'" density="compact" v-model="attributes[attribute.id]" @update:model-value="appConfigDirtied = true" color="primary"></v-switch>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-text>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-list-item>
                  <v-list-item
                      rounded
                      class="px-0 py-0"
                    v-for="app of newApps"
                    :key="app.name"
                  >
                    <v-combobox
                      class="app-combobox"
                      clearable
                      :items="filteredTenantApplications"
                      item-title="name"
                      hide-details
                      density="comfortable"
                      :disabled="!filteredTenantApplications.length"
                      :placeholder="`${filteredTenantApplications.length ? 'Search applications to assign to this user' : 'There are no more applications to assign to this user'}`"
                      return-object
                      :menu-props="{ maxWidth: 452 }"
                    >
                      <template v-slot:selection="{ item }">
                        <div class="d-flex flex-column">
                          <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                          <v-list-item-subtitle>{{ item.raw.description }}</v-list-item-subtitle>
                        </div>
                      </template>
                      <template v-slot:item="{ item }">
                        <div class="d-flex flex-column">
                          <v-list-item @click="async () => { newApp = item.raw; await addApplicationAccess() }">
                            <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.raw.description }}</v-list-item-subtitle>
                          </v-list-item>
                        </div>
                      </template>
                    </v-combobox>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              :disabled="loading || !appConfigDirtied || !hasRoleAssigned()"
              color="primary"
              @click="saveUser(); appConfigDirtied = false;"
            >
              Save applications
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<style scoped>
</style>
