import { appAxios } from '../app.axios';
import { Application, Role, Tenant, User } from '@/types';
import { useUserStore } from "@/stores/userStore";
import Transformation from "@/classes/transformation";

export class TenantService {
  public async searchTenants(
    take = 0, skip = 0, sort = '',
    desc = false, filters: Record<string, Record<string, boolean>> = {},
    searchNames: string | null = null): Promise<Tenant[]> {
    let filtersString = '';

    const filterQuery = Transformation.filterMapToQueryString(filters);

    if (filterQuery) {
      filtersString += `&${filterQuery}`;
    }
    if (searchNames) {
      filtersString += `&searchNames=${encodeURIComponent(searchNames)}`;
    }
    const url = `/tenants/search?take=${take}&skip=${skip}&sort=${sort}&desc=${desc}${filtersString}`;
    return (await appAxios.get(url)).data;
  }
  public async addTenant(tenant: Tenant) {
    const url = `/tenants`;
    return (await appAxios.post(url, tenant)).data;
  }
  public async removeTenant(tenant: Tenant) {
    const url = `/tenants/${tenant.id}`;
    return (await appAxios.delete(url)).data;
  }
  public async updateTenant(tenant: Tenant) {
    const url = `/tenants/${tenant.id}`;
    const payload: any = {
      application_ids: tenant.applications?.map((a)=> a.id),
      name: tenant.name,
      state: tenant.state,
      theme_configuration: tenant.theme_configuration
    }
    return (await appAxios.put(url, payload)).data;
  }
  public async getTenants() {
    const url = `/tenants`;
    return (await appAxios.get(url)).data;
  }

  public async getTenant(tenantId: number) {
    const url = `/tenants/${tenantId}`;
    return (await appAxios.get(url)).data;
  }

  public async updateUserRoleAndApplicationAccess(tenantId: number, userId: number, applications: Application[]) {
    const url = `/tenants/${tenantId}/users/${userId}`;
    return (await appAxios.put(url, { applications })).data;
  }

  public async assignApplicationToTenants(tenants: number[], application: Partial<Application>) {
    const url = `/applications/${application.id}/tenants/add`;
    return (await appAxios.put(url, { tenants })).data;
  }

  public async removeTenantFromApplication(tenants: number[], application: Partial<Application>) {
    const url = `/applications/${application.id}/tenants/remove`;
    return (await appAxios.put(url, { tenants })).data;
  }

  public async setUsersOnApplication(tenantId: number, applicationId: number, users: {
    role: any;
    user_id: number | undefined;
    attributes: any
    application_id?: number;
  }[]) {
    const url = `/tenants/${tenantId}/applications/${applicationId}/users`;
    return (await appAxios.put(url, { users })).data;
  }

  public async getEmbedUrl(tenantId: number, applicationId: number) {
    const url = `/tenants/${tenantId}/applications/${applicationId}/url`;
    return (await appAxios.get(url)).data;
  }

  public async getTokenUrlAndToken(tenantId: number, applicationId: number) {
    const url = `/tenants/${tenantId}/applications/${applicationId}/token`;
    return (await appAxios.get(url)).data;
  }

  public async getApplication(tenantId: number, applicationId: number) {
    const url = `/tenants/${tenantId}/applications/${applicationId}`;
    return (await appAxios.get(url)).data;
  }

}

export default new TenantService();
