import {defineStore} from "pinia";
import {isJsonString} from "@/util";

interface State {
  appLoading: boolean;
  debugMode: boolean;
}
const debugMode = localStorage.getItem('debugMode');

export const useAppStore = defineStore( 'appPortalStore', {
  state: (): State => ({
    appLoading: false,
    debugMode: true,
    // debugMode: debugMode !== null && isJsonString(debugMode) ? JSON.parse(debugMode) : false
  }),
  actions: {
    toggleAppLoading(isLoading: boolean) {
      this.appLoading = isLoading;
    },
  },
})