<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import {useDisplay} from "vuetify";
import ApplicationService from "@/services/application.service";
import {Application, Tenant, User} from "@/types";
import { VAceEditor } from 'vue3-ace-editor';
//eslint-disable-next-line
const ace = require('ace-builds');
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
import {useNotificationStore} from "@/stores/notificationStore";
import TenantService from "@/services/tenant.service";
import {useTenantStore} from "@/stores/tenantStore";

@Component({ components: { VAceEditor }})
export default class ApplicationsManagement extends Vue {
  private notificationStore = useNotificationStore();
  private tenantStore = useTenantStore();
  private applications: Application[] = [];
  private iconLibrary: { name: string }[] = [];
  private selectedApplications: Application[] = [];
  private headers: Array<{ title: string; value: string; sortable: boolean; width?: string }> = [
    { title: '', value: 'icon', sortable: false, width: '1%' },
    { title: 'Name', value: 'name', sortable: true, width: '20%' },
    { title: 'Type', value: 'type', sortable: true, width: '20%' },
    { title: 'Description', value: 'description', sortable: true, width: '30%' },
    { title: 'Accounts', value: 'accounts', sortable: true },
    { title: '', value: 'actions', sortable: false },
  ];
  private mdAndUp = useDisplay().mdAndUp;
  private loading = false;
  private deleting = false;
  private currentApplication: Partial<Application> | null = null;
  private currentTenant: Partial<Tenant> | null = null;
  private tenants: Tenant[] = [];
  private tenantsToAdd: Tenant[] = [];
  private tenantsToRemove: Tenant[] = [];
  private applicationTenants: Tenant[] = [];
  private newTenant: Tenant | null = null;
  private editApplicationDetailsDialog = false;
  private editAccountsDialog = false;
  private iconSearch: string | null = null;
  private iconListLoading = false;
  private editApplicationConfigurationDialog = false;
  private removeDialog = false;
  private removeAccountDialog = false;
  private newTenants: Partial<Tenant>[] = [];

  private app: { name: string, description: string; type: string; details: any; icon: string | null } = {
    name: '',
    description: '',
    type: '',
    details: {},
    icon: null
  }
  private tab: 'details' | 'config' = 'details';
  private disabled = '';
  private appTypes: Array<{ name: string; type: string; details?: Record<string, string>}> = [
    {
      name: 'External',
      type: 'external',
      details: {}
    },
    {
      name: 'Quicksight',
      type: 'quicksight',
      details: {
        "dashboardId":"",
      }
    },
  ]

  private async mounted() {
    this.loading = true;
    try {
      await this.fetchApplications();
      await this.fetchTenants();
    } finally {
      this.loading = false;
    }
  }

  private async fetchApplications() {
    this.applications = await ApplicationService.getAllApplications();
  }

  private async fetchTenants() {
    this.tenants = await TenantService.getTenants();
  }

  private async registerApp() {
    this.loading = true;
    try {
      if (Object.keys(this.app.details).length > 0) {
        this.app.details = JSON.stringify(this.app.details);
      } else {
        this.app.details = JSON.stringify(this.appTypes.find((t) => t.type === this.app.type)?.details);
      }
      this.app.icon = this.iconSearch && this.iconSearch.trim().length > 0 ? this.iconSearch : null;
      await ApplicationService.registerApplication(this.app);
    } finally {
      await this.fetchApplications();
      this.app = { name: '', type: '', description: '', details: {}, icon: null};
      this.loading = false;
    }
  }

  private updateEditor(text: string) {
    const isJson = (str: string) => {
      try {
        const json = JSON.parse(str);
        return typeof json === 'object' && json !== str || false
      } catch (e) {
        return false;
      }
    }
    if (isJson(text)) {
      if (this.currentApplication) {
        this.currentApplication.details = JSON.parse(text);
      }
      if (this.app) {
        this.app.details = JSON.parse(text)
      }
    } else {
      this.disabled = 'Invalid JSON'
    }
  }

  private randomColor(str: string) {
    let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += value.toString(16).padStart(2, '0')
    }
    return color
  }

  private truncate(text: string) {
    return text.substring(0, 100) + "...";
  }

  private editApplicationDetails(item: Application) {
    this.currentApplication = {...item};

    if (!this.iconSearch && this.currentApplication && this.currentApplication.icon) {
      this.iconSearch = this.currentApplication.icon;

      this.iconLibrary = [];
      this.iconLibrary.push({name: this.iconSearch});
    }

    this.editApplicationDetailsDialog = true;
  }

  private editApplicationConfiguration(item: Application) {
    this.currentApplication = {...item};

    this.editApplicationConfigurationDialog = true;
  }

  private editAccounts(item: Application) {
    this.currentApplication = {...item};
    this.getApplicationTenants(item);

    this.editAccountsDialog = true;
    this.tenantsToAdd = [];
  }

  private getApplicationTenantAccess(item: Application) {
    return this.tenants.filter((t) => t.applications?.map((a) => a.id).includes(item.id)) || [];
  }

  private remainingTenantNames(item: Application) {
    const tenants = this.getApplicationTenantAccess(item);
    return tenants.slice(5);
  }

  private async saveApplication() {
    this.loading = true;
    if (this.currentApplication) {
      if (this.iconSearch?.length || this.currentApplication.icon) {
        if (this.editApplicationDetailsDialog) {
          this.currentApplication.icon = this.iconSearch && this.iconSearch.trim().length > 0 ? this.iconSearch : null;
        }
      } else {
        this.iconSearch = null;
        this.currentApplication.icon = null;
      }
      try {
        await ApplicationService.updateApplication(this.currentApplication);
        await this.fetchApplications();
      } catch (e) {
        const err = e as Error;
        this.notificationStore.addToast(err)
        throw err;
      } finally {
        this.loading = false;
        this.iconSearch = '';
        this.editApplicationDetailsDialog = false;
        this.editApplicationConfigurationDialog = false;
      }
    }
  }

  private async removeApplication() {
    this.loading = true;
    try {
      const app = await ApplicationService.removeApplication(this.selectedApplications[0].id)
      this.removeDialog = false;
      this.selectedApplications = [];
    } catch (e) {
      const err = e as Error;
      this.disabled = err.message;
    } finally {
      this.loading = false;
      await this.fetchApplications();
    }
  }

  private clearFocus() {
    // Fixes a bug where the prepended icon doesn't appear until you un-focus the input after selection
    (document.activeElement as any).blur();
  }

  private async lazyLoadIcons(search: string) {
    if (search == null) {
      this.iconSearch = '';
      this.iconLibrary = [];
      return;
    }

    if (search?.length < 3) { return; }
    this.iconSearch = search;
    this.iconListLoading = true;
    ApplicationService.searchIcons(search.toLowerCase()).then((icons) => {
      this.iconLibrary = icons;
    }).finally(() => this.iconListLoading = false);
  }
  
  private addTenantRow(application: Application) {
    if (application) {
      this.newTenants.push({ name: '' });
    }
  }

  private addAllTenants(application: Application) {
    if (application) {
      this.applicationTenants = [];
      this.newTenants = [];
      this.tenantsToAdd = this.tenants;
    }
  }

  private removeAllTenants(application: Application) {
    if (application) {
      this.applicationTenants = [];
      this.newTenants = [];
      this.tenantsToAdd = [];
      this.tenantsToRemove = this.tenants;
    }
  }

  private async removeTenantFromApplication(tenant: Tenant) {
    this.tenantsToRemove.push(tenant);
    this.applicationTenants = this.applicationTenants.filter((a) => a.id !== tenant.id)
  }

  private getApplicationTenants(app: Application) {
    this.applicationTenants = this.tenants.filter((t) => t.applications?.map((a) => a.id).includes(Number(app.id))) || [];
  }

  private addTenantAccess() {
    if (this.newTenant && (this.currentApplication || this.app)) {
      this.tenantsToAdd = this.tenantsToAdd || []
      this.tenantsToAdd.push(this.newTenant);
      this.newTenant = null;
      this.newTenants = [];
    }
  }

  private async saveApplicationTenants(removeSingle: any = {}) {
    if (this.currentApplication) {
      await TenantService.assignApplicationToTenants(this.tenantsToAdd.map((a) => Number(a.id)), this.currentApplication);
      if (this.tenantsToRemove.length) {
        await TenantService.removeTenantFromApplication(this.tenantsToRemove.map((a) => Number(a.id)), this.currentApplication)
      }
      if (removeSingle.id) {
        await TenantService.removeTenantFromApplication([removeSingle.id], this.currentApplication)
      }
    }
    await this.fetchTenants();
    if (this.tenantStore.tenantId) {
      await this.tenantStore.fetchTenantApplications(this.tenantStore.tenantId)
    }
    this.tenantsToAdd = [];
    this.editAccountsDialog = false;
    if (this.deleting) {
      this.removeDialog = true;
      this.disabled = '';
    }
  }

  private removeNewTenant(tenant: Tenant) {
    this.tenantsToAdd = this.tenantsToAdd.filter((t) => t.id !== tenant.id);
  }

  private get filteredTenantsList() {
    const currentApplicationId = Number(this.currentApplication?.id);
    return this.tenants.filter((tenant) => {
      const hasCurrentApp = tenant.applications?.some((app) => app.id === currentApplicationId);
      const inTenantsToAdd = this.tenantsToAdd.some((t) => t.id === tenant.id);
      return !hasCurrentApp && !inTenantsToAdd;
    });
  }

}
</script>

<template>
  <div>
    <v-row justify="space-between" align="center" dense>
      <v-col cols="12">
        <v-row align="center" dense>
          <v-col cols="12">
            <v-card color="#F4F4F4" class="pa-2 align-content-center" variant="flat" height="48">
              <v-row dense>
                <v-col cols="4" md="auto">
                  <v-dialog width="500" @update:modelValue="app = { name: '', type: '', description: '', details: {}, icon: ''}; iconSearch = ''; iconLibrary = []; tab = 'details'">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" size="small" variant="plain" color="primary">
                        {{ mdAndUp ? 'Register application' : 'Register' }}
                      </v-btn>
                    </template>

                    <template v-slot:default="{ isActive }">
                      <v-card title="Register an application">
                        <v-tabs
                            v-model="tab"
                            :disabled="!app.type"
                        >
                          <v-tab value="details">Details</v-tab>
                          <v-tab value="config">Configuration</v-tab>
                        </v-tabs>
                        <v-card-text>
                          <v-window v-model="tab">
                            <v-window-item value="details">
                              <v-form>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-text-field hide-details density="comfortable" label="Name" v-model="app.name"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-select hide-details density="comfortable" label="Type of application" v-model="app.type" :items="appTypes" item-title="name" item-value="type"></v-select>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-textarea hide-details density="comfortable" label="Description" v-model="app.description"></v-textarea>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-autocomplete @update:model-value="clearFocus" center-affix no-data-text="Start typing to search.." :loading="iconListLoading" @update:search="lazyLoadIcons" :search="iconSearch" clearable :model-value="iconSearch" hide-details density="comfortable" label="Application icon" :items="iconLibrary" item-value="name" item-title="name" :item-props="true">
                                      <template v-slot:item="{ props, item }">
                                        <v-list-item
                                            v-bind="props"
                                            :prepend-icon="`mdi-${item.raw.name}`"
                                            :title="item.raw.name"
                                        >
                                        </v-list-item>
                                      </template>
                                      <template v-slot:selection="{ item }">
                                        <v-list-item :prepend-icon="`mdi-${item.raw.name}`" :title="item.raw.name"></v-list-item>
                                      </template>
                                    </v-autocomplete>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-window-item>
                            <v-window-item value="config">
                              <v-ace-editor
                                  :value="appTypes.find((t) => t.type === app.type) ? JSON.stringify(appTypes.find((t) => t.type === app.type).details, null, 2) : '{}'"
                                  @update:value="disabled = ''; updateEditor($event);"
                                  lang="json"
                                  theme="chrome"
                                  style="height: 210px" />
                            </v-window-item>
                          </v-window>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-tooltip :text="disabled" location="bottom" open-delay="200">
                            <template v-slot:activator="{ props }">
                              <div
                                  v-if="disabled.length"
                                  v-bind="props"
                              >
                                <v-btn
                                    :loading="loading"
                                    :disabled="!app.name || !app.type || loading || !!disabled.length"
                                    color="primary"
                                    @click="registerApp(); isActive.value = false;"
                                >
                                  Register application
                                </v-btn>
                              </div>
                            </template>
                          </v-tooltip>
                          <v-btn
                              v-if="!disabled.length"
                              :loading="loading"
                              :disabled="!app.name || !app.type || loading"
                              color="primary"
                              @click="registerApp(); isActive.value = false;"
                          >
                            Register application
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
                <v-col cols="4" md="auto">
                  <v-dialog width="500" v-model="removeDialog" @update:modelValue="disabled = ''">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" size="small" variant="plain" :disabled="!selectedApplications.length" color="primary">
                        {{ mdAndUp ? 'Remove application' : 'Remove' }}
                      </v-btn>
                    </template>
                    <template v-slot:default>
                      <v-card max-width="460" :loading="loading">
                        <v-card-title>Remove application</v-card-title>
                        <v-card-text>
                          <div v-if="!!disabled.length">
                            <p>
                              <span class="text-error">This application cannot be removed, because it has active accounts assigned to it. You can unassign the application from the accounts, and try again.</span>
                            </p>
                            <p class="text-center mt-4"><v-btn color="primary" variant="plain" @click="editAccounts(selectedApplications[0]); removeDialog = false; deleting = true">Open account assignment</v-btn></p>
                          </div>
                          <span v-else>
                          Are you sure you want to remove this application?
                        </span>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              @click="removeDialog = false">
                            Cancel
                          </v-btn>
                          <v-btn
                              :loading="loading"
                              :disabled="loading || !!disabled.length"
                              color="error"
                              @click="removeApplication">
                            Remove
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                  <v-dialog width="500" v-model="removeAccountDialog">
                    <template v-slot:default="{ isActive }">
                      <v-card>
                        <v-card-title>Remove {{ currentTenant.name }} from {{ currentApplication.name }}?</v-card-title>
                        <v-card-text>
                          Are you sure you want to remove this account from this application? The users in this account will no longer have access to the application or its data.
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              @click="isActive.value = false">
                            Cancel
                          </v-btn>
                          <v-btn
                              :loading="loading"
                              :disabled="loading"
                              color="primary"
                              @click="saveApplicationTenants(currentTenant); isActive.value = false">
                            Remove
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card>

          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-data-table
      :loading="loading"
      color="primary"
      :items="applications"
      :headers="headers"
      show-select
      v-model="selectedApplications"
      select-strategy="single"
      items-per-page="25"
      return-object
      height="calc(100vh - 260px)"
      fixed-header
    >
      <template v-slot:[`item.icon`]="{ item }">
        <v-icon color="grey-darken-1">mdi-{{ item.icon }}</v-icon>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <span class="text-capitalize">{{ item.type }}</span>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <span v-if="item.description.length > 70">{{ truncate(item.description) }}</span>
        <span v-else>{{ item.description }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu>
          <template #activator="{ props }">
            <div class="d-flex justify-end">
              <v-btn v-bind="props" size="32" icon variant="flat" color="primary" v-if="item.state !== 'suspended'"><v-icon size="20">mdi-dots-vertical</v-icon></v-btn>
            </div>
          </template>
          <v-list density="compact">
            <v-list-item @click="editApplicationDetails(item)" prepend-icon="mdi-application-edit" title="Edit application"></v-list-item>
            <v-list-item @click="editApplicationConfiguration(item)" prepend-icon="mdi-application-cog" title="Configure application"></v-list-item>
            <v-list-item @click="editAccounts(item)" prepend-icon="mdi-account-multiple-plus" title="Account assignment"></v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:[`item.accounts`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex" v-for="(tenant, index) of getApplicationTenantAccess(item)" :key="tenant.id">
            <div class="mr-n2">
              <v-tooltip :text="tenant.name" location="bottom" open-delay="200" v-if="index < 5">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" size="32" style="border: 2px solid #ffffff" icon variant="flat" color="primary" @click="currentApplication = {...item}; currentTenant = tenant; removeAccountDialog = true;">{{ tenant.name.charAt(0) }}</v-btn>
                </template>
              </v-tooltip>
            </div>
          </div>
          <v-tooltip v-if="getApplicationTenantAccess(item).length > 5" location="bottom" open-delay="200">
            <template v-slot:activator="{ props }">
              <div v-bind="props" class="ml-4"><a class="link">+ {{ getApplicationTenantAccess(item).length - 5 }} more</a></div>
            </template>
            <template v-slot:default>
              <div v-for="tenant of remainingTenantNames(item)" :key="tenant.id">{{ tenant.name }}</div>
            </template>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <v-dialog width="500" v-model="editAccountsDialog" @afterLeave="tenantsToAdd = []; tenantsToRemove = [];">
      <template v-slot:default>
        <v-card>
          <v-card-title>
            Add or remove accounts from {{ currentApplication.name }}
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row align="center">
                <v-col cols="12" class="pb-0">
                  <v-btn block rounded="pill" color="primary" variant="flat" @click="addTenantRow(currentApplication)" :disabled="!filteredTenantsList.length"> Add account </v-btn>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-btn block rounded="pill" color="primary" variant="flat" @click="addAllTenants(currentApplication)" :disabled="!filteredTenantsList.length">Add all accounts</v-btn>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-btn block rounded="pill" color="primary" variant="flat" @click="removeAllTenants(currentApplication)">Remove all accounts</v-btn>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-list>
                    <v-list-item v-for="tenant of applicationTenants" :key="tenant.name" style="background-color: rgba(0,0,0,0.05)" rounded class="my-2">
                      <v-row no-gutters class="flex-nowrap" justify="space-between" align="center">
                        <v-col cols="11">
                          <v-list-item-title>{{ tenant.name }}</v-list-item-title>
                        </v-col>
                        <v-col cols="1">
                          <v-list-item-action>
                            <v-dialog :attach="true">
                              <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" icon variant="flat" color="transparent" size="32"><v-icon icon="mdi-delete" color="primary" size="24"></v-icon></v-btn>
                              </template>
                              <template v-slot:default="{ isActive }">
                                <v-card max-width="460" min-height="200">
                                  <v-card-title>Remove account from application</v-card-title>
                                  <v-card-text>
                                    Are you sure you want to remove this account from this application? Users in this account will no longer have access to the application or its data.
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="isActive.value = false">
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                        :loading="loading"
                                        :disabled="loading"
                                        color="primary"
                                        @click="removeTenantFromApplication(tenant); isActive.value = false">
                                      Remove
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </template>
                            </v-dialog>
                          </v-list-item-action>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item v-for="tenant of tenantsToAdd" :key="tenant.name" style="background-color: rgba(0,0,0,0.05)" rounded class="my-2">
                      <v-row no-gutters justify="space-between" align="center">
                        <v-col cols="11">
                          <v-list-item-title>{{ tenant.name }}</v-list-item-title>
                        </v-col>
                        <v-col cols="1">
                          <v-list-item-action>
                            <v-btn icon variant="flat" color="transparent" size="32" @click="removeNewTenant(tenant)"><v-icon icon="mdi-delete" color="primary" size="24"></v-icon></v-btn>
                          </v-list-item-action>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item
                        class="mb-1 px-0 pt-0"
                        v-for="tenant of newTenants"
                        :key="tenant.name"
                    >
                      <v-combobox
                          class="app-combobox"
                          clearable
                          :items="filteredTenantsList"
                          item-title="name"
                          hide-details
                          density="comfortable"
                          placeholder="Search accounts"
                          return-object
                          :menu-props="{ maxWidth: 452 }"
                      >
                        <template v-slot:selection="{ item }">
                          <div class="d-flex flex-column">
                            <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                          </div>
                        </template>
                        <template v-slot:item="{ item }">
                          <div class="d-flex flex-column">
                            <v-list-item @click="newTenant = item.raw; addTenantAccess()">
                              <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                            </v-list-item>
                          </div>
                        </template>
                      </v-combobox>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                @click="saveApplicationTenants"
                :loading="loading"
                :disabled="loading"
            >
              Save application
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog width="500" v-model="editApplicationDetailsDialog" @update:model-value="iconSearch = ''">
      <template v-slot:default="{ isActive }">
        <v-card title="Edit application">
          <v-card-text>
            <v-form>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field hide-details density="comfortable" label="Name" v-model="currentApplication.name"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select class="text-capitalize" disabled hide-details density="comfortable" label="Type of application" v-model="currentApplication.type" item-title="name" item-value="type"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea hide-details density="comfortable" label="Description" v-model="currentApplication.description"></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete @update:model-value="clearFocus" center-affix no-data-text="Start typing to search.." :loading="iconListLoading" @update:search="lazyLoadIcons" :search="iconSearch" clearable :model-value="iconSearch" hide-details density="comfortable" label="Application icon" :items="iconLibrary" item-value="name" item-title="name" :item-props="true">
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                          v-bind="props"
                          :prepend-icon="`mdi-${item.raw.name}`"
                          :title="item.raw.name"
                      >
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-list-item :prepend-icon="`mdi-${item.raw.name}`" :title="item.raw.name"></v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip :text="disabled" location="bottom" open-delay="200">
              <template v-slot:activator="{ props }">
                <div
                  v-if="disabled.length"
                  v-bind="props"
                >
                  <v-btn
                    :loading="loading"
                    :disabled="!currentApplication.name || !!disabled.length"
                    color="primary"
                  >
                    Save application
                  </v-btn>
                </div>
              </template>
            </v-tooltip>
            <v-btn
              v-if="!disabled.length"
              :loading="loading"
              :disabled="!currentApplication.name || loading"
              color="primary"
              @click="saveApplication(); isActive.value = false;"
            >
              Save application
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog width="500" v-model="editApplicationConfigurationDialog">
      <template v-slot:default="{ isActive }">
        <v-card :title="`Edit configuration for ${currentApplication.name}`" height="350">
          <v-card-text>
            <v-ace-editor
                :value="JSON.stringify(currentApplication.details, null, 2) || '{}'"
                @update:value="disabled = ''; updateEditor($event);"
                lang="json"
                theme="chrome"
                style="height: 210px" />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip :text="disabled" location="bottom" open-delay="200">
              <template v-slot:activator="{ props }">
                <div
                    v-if="disabled.length"
                    v-bind="props"
                >
                  <v-btn
                      :loading="loading"
                      :disabled="!!disabled.length"
                      color="primary"
                  >
                    Save application
                  </v-btn>
                </div>
              </template>
            </v-tooltip>
            <v-btn
                v-if="!disabled.length"
                :loading="loading"
                :disabled="!currentApplication.name || loading"
                color="primary"
                @click="saveApplication(); isActive.value = false;"
            >
              Save application
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<style scoped>

</style>
