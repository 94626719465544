<script lang="ts">
import {Vue, Component, Prop, Emit, Watch} from 'vue-facing-decorator';
import {Application, Group} from "@/types";
import {useTenantStore} from "@/stores/tenantStore";
import ApplicationService from "@/services/application.service";

@Component({})
export default class AssignApplications extends Vue {
  @Prop() private currentGroup!: any;
  @Prop() private removeApplicationAccessFromGroup!: any;
  private applications: Application[] = [];
  private tenantStore = useTenantStore();

  private appsToAdd: Application[] = [];
  private newApps: Partial<Application>[] = [];
  private newApp: Application | null = null;

  @Emit()
  private updateAppsToAdd() {
    return this.appsToAdd;
  }

  @Watch('appsToAdd', { deep: true })
  private onAppsUpdate() {
    this.updateAppsToAdd()
  }

  private async mounted() {
    await this.fetchApplications()
  }

  private async fetchApplications() {
    if (this.$route.path.includes('admin')) {
      this.applications = await ApplicationService.getAllApplications();
      return;
    }
    if (this.tenantStore?.tenantId) {
      this.applications = await ApplicationService.getApplicationsForTenant(this.tenantStore.tenantId);
    } else {
      this.applications = await ApplicationService.getAllApplications();
    }
  }

  private removeNewApplication(app: Application) {
    this.appsToAdd = this.appsToAdd.filter((a) => a.id !== app.id);
  }

  private addApplicationRow() {
    this.newApps.push({ name: '', description: '' });
  }

  private addApplicationAccess() {
    if (this.newApp && this.currentGroup) {
      this.appsToAdd = this.appsToAdd || []
      this.appsToAdd.push(this.newApp);
      this.newApp = null;
      this.newApps = [];
    }
  }

  private addAllApps(group: Group | null) {
    if (group) {
      group.applications = [];
      this.newApps = [];
      this.appsToAdd = this.applications;
    }
  }

  private removeAllApps(group: Group | null) {
    if (group) {
      group.applications = [];
      this.newApps = [];
      this.appsToAdd = [];
    }
  }

  private get filteredApplications() {
    return this.applications.filter((app: Application) => {
      const addingApp = this.appsToAdd.map((atr) => atr.id).includes(app.id);
      const hasApp = this.currentGroup?.applications?.map((a: Application) => a.id).includes(app.id) || false;

      return !addingApp && !hasApp;
    });
  }
}
</script>

<template>
  <v-form>
    <v-row align="center">
      <v-col cols="12" class="pb-0">
        <v-btn block rounded="pill" color="primary" variant="flat" @click="addApplicationRow(currentGroup)" :disabled="!filteredApplications.length">Add application</v-btn>
      </v-col>
      <v-col cols="6" class="pb-0">
        <v-btn block rounded="pill" color="primary" variant="flat" @click="addAllApps(currentGroup)" :disabled="!filteredApplications.length">Add all apps</v-btn>
      </v-col>
      <v-col cols="6" class="pb-0">
        <v-btn block rounded="pill" color="primary" variant="flat" @click="removeAllApps(currentGroup)">Remove all apps</v-btn>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-list>
          <v-list-item v-for="app of currentGroup?.applications" :key="app.name" style="background-color: rgba(0,0,0,0.05)" rounded class="my-2">
            <v-row no-gutters class="flex-nowrap" justify="space-between" align="center">
              <v-col cols="11">
                <v-list-item-title><v-icon start v-if="app.icon">mdi-{{ app.icon }}</v-icon> {{ app.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
              </v-col>
              <v-col cols="1">
                <v-list-item-action>
                  <v-dialog :attach="true">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" icon variant="flat" color="transparent" size="32"><v-icon icon="mdi-delete" color="primary" size="24"></v-icon></v-btn>
                    </template>
                    <template v-slot:default="{ isActive }">
                      <v-card max-width="460">
                        <v-card-title>Remove application from group</v-card-title>
                        <v-card-text>
                          Are you sure you want to remove this application from this group? This group must have no assigned users in any account in order to be deleted.
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              @click="isActive.value = false">
                            Cancel
                          </v-btn>
                          <v-btn
                              color="primary"
                              @click="removeApplicationAccessFromGroup(app); isActive.value = false">
                            Remove
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-list-item-action>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item v-for="app of appsToAdd" :key="app.name" style="background-color: rgba(0,0,0,0.05)" rounded class="my-2">
            <v-row no-gutters justify="space-between" align="center">
              <v-col cols="11" class="align-content-center">
                <v-list-item-title><v-icon start v-if="app.icon">mdi-{{ app.icon }}</v-icon> {{ app.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
              </v-col>
              <v-col cols="1">
                <v-list-item-action>
                  <v-btn icon variant="flat" color="transparent" size="32" @click="removeNewApplication(app)"><v-icon icon="mdi-delete" color="primary" size="24"></v-icon></v-btn>
                </v-list-item-action>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item
              class="mb-1 px-0 pt-0"
              v-for="app of newApps"
              :key="app.name"
          >
            <v-combobox
                class="app-combobox"
                clearable
                :items="filteredApplications"
                item-title="name"
                hide-details
                density="comfortable"
                placeholder="Search applications"
                return-object
                :menu-props="{ maxWidth: 452 }"
            >
              <template v-slot:selection="{ item }">
                <div class="d-flex flex-column">
                  <v-list-item-title><v-icon start v-if="item.raw.icon">mdi-{{ item.raw.icon }}</v-icon> {{ item.raw.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.raw.description }}</v-list-item-subtitle>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div class="d-flex flex-column">
                  <v-list-item @click="newApp = item.raw; addApplicationAccess()">
                    <v-list-item-title><v-icon start v-if="item.raw.icon">mdi-{{ item.raw.icon }}</v-icon> {{ item.raw.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.raw.description }}</v-list-item-subtitle>
                  </v-list-item>
                </div>
              </template>
            </v-combobox>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-form>
</template>

<style scoped>

</style>