import {ThemeDefinition} from "vuetify";

export const currentTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#8C0C0C',
    secondary: '#454545',
    tertiary: '#F4F4F4',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
}

export const logo = `https://fakeimg.pl/200x64?text=ThemeLogo+2&font=noto`
export const favicon = 'https://fakeimg.pl/32x32/454545'
export const font = { name: 'Roboto', url: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'};
