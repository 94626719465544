import {defineStore} from "pinia";
import {v4 as uuidv4} from "uuid";

interface State {
  toastMessages: Record<string, string>;
  snackbarMessages: Record<string, { icon: string; message: string; }>;
}

export const useNotificationStore = defineStore( 'appPortalNotificationStore', {
  state: (): State => ({
    toastMessages: {},
    snackbarMessages: {},
  }),
  actions: {
    addToast(msg: any) {
      const key: string = uuidv4();

      const messages: {[id: string]: string} = this.toastMessages;
      messages[key] = msg.message;
      this.toastMessages = messages;
    },
    removeToast(key: string) {
      delete this.toastMessages[key];
    },
    removeAllToasts() {
      for (const key in this.toastMessages) {
        delete this.toastMessages[key];
      }
    },
    addSnackbar(msg: any) {
      const key: string = uuidv4();

      const messages: {[id: string]: { icon: string; message: string; }} = this.snackbarMessages;
      messages[key] = msg;
      this.snackbarMessages = messages;

    },
    removeSnackbar(key: string) {
      delete this.snackbarMessages[key];
    },
  },
})