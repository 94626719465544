<template>
  <v-row justify="center" align="center">
    <v-col cols="12" md="10" lg="6" class="pa-4">
      <v-card color="primary" class="px-4 py-6">
        <v-row class="pb-40" justify="space-around">
          <v-col cols="12" class="text-center">
            <span class="text-white text-h5 font-weight-bold">Log in</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <LoginError :error="error" />
          </v-col>
        </v-row>
        <v-form @submit.prevent="checkEmail(username)" v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  autofocus
                  class="custom-field"
                  placeholder="Email address"
                  persistent-placeholder
                  variant="outlined"
                  bg-color="#ffffff"
                  v-model="username"
                  @update:modelValue="clearError"
                  :rules="[v => !!v && v.length && v.includes('@') || false]"
                  autocomplete="username"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col class="relative d-flex justify-center">
              <v-btn width="95" type="submit" color="secondary" :disabled="!username || !valid || loading" :loading="loading" class="text-none">Confirm</v-btn>
            </v-col>
          </v-row>
        </v-form>

      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {Component, Vue, toNative, Prop, Watch, Emit} from "vue-facing-decorator";
import ActionButton from "@/components/ActionButton.vue";
import LoginError from "@/components/LoginError.vue";
@Component({
  components: {LoginError, ActionButton}
})
export default class EmailPrompt extends Vue {
  @Prop() private queryUsername!: string;
  @Prop() private error!: string;
  @Prop() private loading!: boolean;
  private username = '';
  private valid = false;

  @Emit()
  private clearError() {
    return true;
  }
  @Emit()
  private checkEmail(username: string): string {
    return username;
  }
  @Watch('queryUsername', {immediate: true})
  private onQueryParamUpdate()  {
    this.username = this.queryUsername;
  }
}
</script>