<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';

@Component({})
export default class NoApplications extends Vue {

}
</script>
<template>
  <v-row no-gutters class="fill-height" justify="center">
    <v-col cols="12" class="d-flex justify-center fill-height">
      <v-row justify="center" align="center" no-gutters>
        <v-col class="mx-auto">
          <p class="text-center font-weight-bold text-404">No applications to display</p>
          <div class="font-weight-bold text-h5 text-center">This user does not have any applications assigned.</div>
        </v-col>
        <v-col cols="12">
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<style scoped>

</style>