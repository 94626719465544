import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = { class: "d-flex flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_list_item_action = _resolveComponent("v-list-item-action")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { align: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            class: "pb-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                block: "",
                rounded: "pill",
                color: "primary",
                variant: "flat",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addApplicationRow(_ctx.currentGroup))),
                disabled: !_ctx.filteredApplications.length
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Add application")
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "6",
            class: "pb-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                block: "",
                rounded: "pill",
                color: "primary",
                variant: "flat",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addAllApps(_ctx.currentGroup))),
                disabled: !_ctx.filteredApplications.length
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Add all apps")
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "6",
            class: "pb-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                block: "",
                rounded: "pill",
                color: "primary",
                variant: "flat",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeAllApps(_ctx.currentGroup)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Remove all apps")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            class: "pt-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentGroup?.applications, (app) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      key: app.name,
                      style: {"background-color":"rgba(0,0,0,0.05)"},
                      rounded: "",
                      class: "my-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, {
                          "no-gutters": "",
                          class: "flex-nowrap",
                          justify: "space-between",
                          align: "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "11" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => [
                                    (app.icon)
                                      ? (_openBlock(), _createBlock(_component_v_icon, {
                                          key: 0,
                                          start: ""
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-" + _toDisplayString(app.icon), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : _createCommentVNode("", true),
                                    _createTextVNode(" " + _toDisplayString(app.name), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_list_item_subtitle, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(app.description), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { cols: "1" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_action, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_dialog, { attach: true }, {
                                      activator: _withCtx(({ props }) => [
                                        _createVNode(_component_v_btn, _mergeProps(props, {
                                          icon: "",
                                          variant: "flat",
                                          color: "transparent",
                                          size: "32"
                                        }), {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_icon, {
                                              icon: "mdi-delete",
                                              color: "primary",
                                              size: "24"
                                            })
                                          ]),
                                          _: 2
                                        }, 1040)
                                      ]),
                                      default: _withCtx(({ isActive }) => [
                                        _createVNode(_component_v_card, { "max-width": "460" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_card_title, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode("Remove application from group")
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_card_text, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode(" Are you sure you want to remove this application from this group? This group must have no assigned users in any account in order to be deleted. ")
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_v_card_actions, null, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_spacer),
                                                _createVNode(_component_v_btn, {
                                                  onClick: ($event: any) => (isActive.value = false)
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(" Cancel ")
                                                  ]),
                                                  _: 2
                                                }, 1032, ["onClick"]),
                                                _createVNode(_component_v_btn, {
                                                  color: "primary",
                                                  onClick: ($event: any) => {_ctx.removeApplicationAccessFromGroup(app); isActive.value = false}
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(" Remove ")
                                                  ]),
                                                  _: 2
                                                }, 1032, ["onClick"])
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appsToAdd, (app) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      key: app.name,
                      style: {"background-color":"rgba(0,0,0,0.05)"},
                      rounded: "",
                      class: "my-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, {
                          "no-gutters": "",
                          justify: "space-between",
                          align: "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "11",
                              class: "align-content-center"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_title, null, {
                                  default: _withCtx(() => [
                                    (app.icon)
                                      ? (_openBlock(), _createBlock(_component_v_icon, {
                                          key: 0,
                                          start: ""
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-" + _toDisplayString(app.icon), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : _createCommentVNode("", true),
                                    _createTextVNode(" " + _toDisplayString(app.name), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_list_item_subtitle, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(app.description), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { cols: "1" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_list_item_action, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_btn, {
                                      icon: "",
                                      variant: "flat",
                                      color: "transparent",
                                      size: "32",
                                      onClick: ($event: any) => (_ctx.removeNewApplication(app))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, {
                                          icon: "mdi-delete",
                                          color: "primary",
                                          size: "24"
                                        })
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newApps, (app) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      class: "mb-1 px-0 pt-0",
                      key: app.name
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_combobox, {
                          class: "app-combobox",
                          clearable: "",
                          items: _ctx.filteredApplications,
                          "item-title": "name",
                          "hide-details": "",
                          density: "comfortable",
                          placeholder: "Search applications",
                          "return-object": "",
                          "menu-props": { maxWidth: 452 }
                        }, {
                          selection: _withCtx(({ item }) => [
                            _createElementVNode("div", _hoisted_1, [
                              _createVNode(_component_v_list_item_title, null, {
                                default: _withCtx(() => [
                                  (item.raw.icon)
                                    ? (_openBlock(), _createBlock(_component_v_icon, {
                                        key: 0,
                                        start: ""
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("mdi-" + _toDisplayString(item.raw.icon), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : _createCommentVNode("", true),
                                  _createTextVNode(" " + _toDisplayString(item.raw.name), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_v_list_item_subtitle, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.raw.description), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]),
                          item: _withCtx(({ item }) => [
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode(_component_v_list_item, {
                                onClick: ($event: any) => {_ctx.newApp = item.raw; _ctx.addApplicationAccess()}
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item_title, null, {
                                    default: _withCtx(() => [
                                      (item.raw.icon)
                                        ? (_openBlock(), _createBlock(_component_v_icon, {
                                            key: 0,
                                            start: ""
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("mdi-" + _toDisplayString(item.raw.icon), 1)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true),
                                      _createTextVNode(" " + _toDisplayString(item.raw.name), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_list_item_subtitle, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.raw.description), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ])
                          ]),
                          _: 1
                        }, 8, ["items"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}