<template>
  <v-dialog width="500" @afterLeave="step = 1; resetDialog(); group.name = '';">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" size="small" variant="plain" color="primary">
        {{ mdAndUp && system ? 'Add system group' : mdAndUp && !system ? 'Add user group' : 'Add' }}
      </v-btn>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card :title="system ? 'Add and configure a system group' : 'Add and configure a user group'">
        <v-card-text>
          <div>
            <div v-if="system">
              <p class="mb-2 text-body-2">System groups are available to all accounts. </p>
              <p class="mb-2 text-body-2">
                Name the group, and optionally assign applications. Applications can be removed from a group at any time.
              </p>
            </div>
            <p class="mb-2 text-body-2" v-else>
              Name the group, and optionally assign applications, users, or both. Applications and users can be removed from a group at any time.
            </p>
          </div>
          <v-stepper elevation="0" @update:modelValue="step = $event" :model-value="step" class="ma-0 add-group">
            <v-stepper-header style="box-shadow: none" class="mb-4">
              <v-stepper-item
                  color="primary"
                  :ripple="false"
                  title="Group"
                  :value="1"
                  icon="mdi-pencil"
                  editable
                  :complete="step > 1"
              ></v-stepper-item>
              <v-stepper-item

                  :disabled="disabled"
                  color="primary"
                  :ripple="false"
                  title="Applications"
                  edit-icon="mdi-application"
                  editable
                  :value="2"
              ></v-stepper-item>
              <v-stepper-item
                  :disabled="disabled"
                  color="primary"
                  v-if="!system"
                  :ripple="false"
                  title="Users"
                  edit-icon="mdi-account-multiple"
                  :value="3"
                  editable
              ></v-stepper-item>
            </v-stepper-header>
            <v-stepper-window :model-value="step" class="ma-0">
              <v-stepper-window-item :value="1" class="ma-0">
                <v-form>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          density="compact"
                          label="Group name"
                          v-model="group.name"
                          :rules="[validateUniqueName]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-stepper-window-item>
              <v-stepper-window-item :value="2" class="ma-0">
                <AssignApplications
                    :currentGroup="group"
                    @updateAppsToAdd="appsToAdd = $event"
                    :removeApplicationAccessFromGroup="removeApplicationAccessFromGroup"
                />
              </v-stepper-window-item>
              <v-stepper-window-item :value="3" class="ma-0" v-if="!system">
                <AssignUsers
                    :currentGroup="group"
                    @updateUsersToAdd="usersToAdd = $event"
                    :removeUserFromGroup="removeUserFromGroup"
                />
              </v-stepper-window-item>

            </v-stepper-window>
          </v-stepper>

        </v-card-text>

        <v-card-actions>

          <v-btn
              v-if="step === 1"
              color="grey"
              @click="isActive.value = false; resetDialog()"
          >
           Cancel
          </v-btn>
          <v-btn
              v-if="step > 1"
              color="grey"
              @click="step--"
          >
            <v-icon start>mdi-chevron-left</v-icon> Back
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn
              v-if="step === 1"
              :loading="loading"
              :disabled="disabled"
              color="secondary"
              @click="createGroup(); isActive.value = false"
          >
            Create group
          </v-btn>
          <v-btn
              v-if="step === 2 && system"
              :loading="loading"
              :disabled="disabled || !appsToAdd.length"
              color="primary"
              @click="createGroupWithApplicationsAndUsers(); isActive.value = false"
          >
            Create group
          </v-btn>
          <v-btn
              v-if="step === 1"
              :disabled="disabled"
              color="primary"
              @click="step++"
          >
            Next <v-icon end>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
              v-if="step === 2 && !system"
              :disabled="disabled || !appsToAdd.length"
              color="secondary"
              @click="createGroupWithApplicationsAndUsers(); isActive.value = false"
          >
            Create group
          </v-btn>
          <v-btn
              v-if="step === 2 && !system"
              :disabled="disabled"
              color="primary"
              @click="step++"
          >
            {{ appsToAdd.length ? 'Next' : 'Skip' }} <v-icon end>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
              v-if="step === 3"
              :disabled="disabled || !usersToAdd.length"
              color="primary"
              @click="createGroupWithApplicationsAndUsers(); isActive.value = false;"
          >
            Create group
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script lang="ts">
import {Component, Emit, Prop, Vue} from "vue-facing-decorator";
import {Application, Group, User} from "@/types";
import {useDisplay} from "vuetify";
import AssignApplications from "@/components/groups/AssignApplications.vue";
import GroupService from "@/services/group.service";
import {useTenantStore} from "@/stores/tenantStore";
import AssignUsers from "@/components/groups/AssignUsers.vue";

@Component({
  components: {AssignUsers, AssignApplications}
})
export default class CreateGroup extends Vue {
  @Prop() private submit!: any;
  @Prop() private removeApplicationAccessFromGroup!: any;
  @Prop() private removeUserFromGroup!: any;
  @Prop() private resetDialog!: any;
  @Prop( { default: false }) private system!: boolean;
  @Prop() private disabled!: boolean;
  @Prop() private loading!: boolean;
  @Prop() private group!: Group;
  private allGroups: Group[] = [];
  private appsToAdd: Application[] = [];
  private usersToAdd: User[] = [];
  private mdAndUp = useDisplay().mdAndUp;
  private tenantStore = useTenantStore();
  private step = 1;

  @Emit()
  private fetchGroups() {
    return true;
  }

  private async mounted() {
    this.allGroups = await GroupService.getAllGroups(this.tenantStore.tenantId)
  }

  private async createGroup() {
    try {
      if (this.group && !this.disabled) {
        if (this.system) {
          await GroupService.createSystemGroup({ name: this.group.name });
        } else {
          if (this.tenantStore?.tenantId) {
            await GroupService.createGroup({name: this.group.name, tenantId: this.tenantStore.tenantId});
          }
        }
      }
    } finally {
      this.groupCreated()
    }
  }

  private async createGroupWithApplicationsAndUsers() {
    try {
      const applicationIds = this.appsToAdd?.map(a => a.id) || [];
      const userIds = this.usersToAdd?.map(u => u.id).filter((u): u is number => u !== undefined) || [];
      const tenantId = this.system ? null : this.tenantStore.tenantId
      await GroupService.createGroupWithApplicationsAndUsers({ name: this.group.name, tenantId: tenantId, applicationIds, userIds});
    } finally {
      this.groupCreated()
    }
  }

  private groupCreated() {
    this.fetchGroups()
    this.resetDialog();
  }

  private get validateUniqueName() {
    return (value: string) => {
      if (!value) {
        return 'Group name is required';
      }

      const matchingGroup = this.allGroups.find((g) => g.name.trim() === value.trim());

      if (matchingGroup) {
        return matchingGroup.tenantId
            ? 'Group name already exists in the active account'
            : 'System group name already exists';
      }

      return true;
    };
  }
}
</script>

<style>
.add-group .v-stepper-window {
  margin: 0;
}
</style>