import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex"
}
const _hoisted_2 = {
  key: 1,
  class: "d-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_row, {
      justify: "space-between",
      align: "center",
      dense: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              align: "center",
              dense: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      color: "#F4F4F4",
                      variant: "flat",
                      class: "pa-2",
                      height: "48"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, {
                          dense: "",
                          align: "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "6",
                              md: "8"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_row, {
                                  "no-gutters": "",
                                  align: "center"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, {
                                      cols: "5",
                                      md: "auto"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_dialog, {
                                          width: "500",
                                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetDialog()))
                                        }, {
                                          activator: _withCtx(({ props }) => [
                                            _createVNode(_component_v_btn, _mergeProps(props, {
                                              size: "small",
                                              variant: "plain",
                                              color: "primary"
                                            }), {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.mdAndUp ? 'Add admin user' : 'Add'), 1)
                                              ]),
                                              _: 2
                                            }, 1040)
                                          ]),
                                          default: _withCtx(({ isActive }) => [
                                            _createVNode(_component_v_card, { title: "Add admin user" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_card_text, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_form, null, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_v_row, null, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "6"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_text_field, {
                                                                  "hide-details": "",
                                                                  density: "compact",
                                                                  label: "First name",
                                                                  modelValue: _ctx.user.first_name,
                                                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.first_name) = $event))
                                                                }, null, 8, ["modelValue"])
                                                              ]),
                                                              _: 1
                                                            }),
                                                            _createVNode(_component_v_col, {
                                                              cols: "12",
                                                              md: "6"
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_text_field, {
                                                                  "hide-details": "",
                                                                  density: "compact",
                                                                  label: "Last name",
                                                                  modelValue: _ctx.user.last_name,
                                                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.last_name) = $event))
                                                                }, null, 8, ["modelValue"])
                                                              ]),
                                                              _: 1
                                                            }),
                                                            _createVNode(_component_v_col, { cols: "12" }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_v_text_field, {
                                                                  "hide-details": "",
                                                                  density: "compact",
                                                                  label: "Email",
                                                                  modelValue: _ctx.user.email,
                                                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.email) = $event))
                                                                }, null, 8, ["modelValue"])
                                                              ]),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_v_card_actions, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_v_spacer),
                                                    _createVNode(_component_v_btn, {
                                                      loading: _ctx.loading,
                                                      disabled: !_ctx.user.first_name || !_ctx.user.last_name || !_ctx.user.email || _ctx.loading,
                                                      color: "primary",
                                                      onClick: ($event: any) => {_ctx.addSuperUser(); isActive.value = false}
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(" Add admin user ")
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["loading", "disabled", "onClick"])
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, {
                                      cols: "5",
                                      md: "auto"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_btn, {
                                          size: "small",
                                          variant: "plain",
                                          disabled: !_ctx.selectedUsers.length,
                                          color: "primary",
                                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.removeSuperUser()))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.mdAndUp ? 'Remove admin user' : 'Remove'), 1)
                                          ]),
                                          _: 1
                                        }, 8, ["disabled"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "6",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_row, { "no-gutters": "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, {
                                      cols: "12",
                                      class: "d-flex justify-end"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          "hide-details": "",
                                          density: "compact",
                                          placeholder: "Search admin users",
                                          "append-inner-icon": "mdi-magnify",
                                          modelValue: _ctx.searchNames,
                                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.searchNames) = $event)),
                                          variant: "underlined"
                                        }, null, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_data_table, {
      items: _ctx.users,
      headers: _ctx.headers,
      "show-select": "",
      "return-object": "",
      modelValue: _ctx.selectedUsers,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedUsers) = $event)),
      height: "calc(100vh - 260px)",
      "onUpdate:sortBy": _ctx.updateTableOptions
    }, {
      [`item.full_name`]: _withCtx(({ item }) => [
        _createTextVNode(_toDisplayString(_ctx.fullName(item.first_name, item.last_name)), 1)
      ]),
      [`item.status`]: _withCtx(({ item }) => [
        (item.status === 'active')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_v_icon, {
                start: "",
                icon: "mdi-circle",
                color: "success"
              }),
              _createTextVNode(" Active ")
            ]))
          : _createCommentVNode("", true),
        (item.status === 'pending')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_v_icon, {
                start: "",
                icon: "mdi-circle",
                color: "grey"
              }),
              _createTextVNode(" Pending ")
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, 1032, ["items", "headers", "modelValue", "onUpdate:sortBy"])
  ]))
}