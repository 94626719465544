import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fill-height embed-container overflow-x-hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_card = _resolveComponent("v-card")!

  return ((_ctx.userStore.isManager || _ctx.userStore.isSuperAdmin))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_app_bar, {
          height: "64",
          flat: ""
        }, {
          default: _withCtx(() => [
            (_ctx.mdAndUp)
              ? (_openBlock(), _createBlock(_component_v_tabs, {
                  key: 0,
                  "bg-color": "background",
                  color: "primary",
                  class: "header-tabs"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_tab, {
                      "slider-color": "transparent",
                      disabled: "",
                      class: "enabled"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, { start: "" }, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi-account-group")
                          ]),
                          _: 1
                        }),
                        _createTextVNode(" Account management")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_tabs, {
              "align-tabs": "center",
              "bg-color": "background",
              color: "primary",
              class: "menu-tabs",
              style: _normalizeStyle(_ctx.mdAndUp ? 'margin-left: -450px' : 'margin-left: 0')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_tab, {
                  exact: "",
                  to: { name: 'users' }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("User management")
                  ]),
                  _: 1
                }),
                (_ctx.appStore.debugMode)
                  ? (_openBlock(), _createBlock(_component_v_tab, {
                      key: 0,
                      to: { name: 'group-management' }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Group management")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["style"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_card, {
          variant: "flat",
          class: "rounded-0 pa-0 fill-height"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_window, { class: "fill-height" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_window_item, { class: "fill-height" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_view)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}