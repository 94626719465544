<script lang="ts">
import {Vue, Component, Watch} from 'vue-facing-decorator';
import {useDisplay} from "vuetify";
import { useUserStore } from "@/stores/userStore";
import {useTenantStore} from "@/stores/tenantStore";
import {useNotificationStore} from "@/stores/notificationStore";
import Toast from '@/components/Toast.vue';
import {Application} from "@/types";
import ApplicationService from "@/services/application.service";
import {RouteScope} from "@/router";
import {useAppStore} from "@/stores/appStore";

@Component({
  components: { Toast } })
export default class Layout extends Vue {
  private RouteScope = RouteScope;
  private appStore = useAppStore();
  private userStore = useUserStore();
  private tenantStore = useTenantStore();
  private notificationStore = useNotificationStore();

  private mdAndUp = useDisplay().mdAndUp;
  private mdAndDown = useDisplay().mdAndDown;
  private lgAndUp = useDisplay().lgAndUp;
  private smAndDown = useDisplay().smAndDown;
  private smAndUp = useDisplay().smAndUp;

  private activeBottomMenu = 0;
  private activeSubMenu = 0;
  private drawer = true;
  private rail = true;
  private logoLoaded = false;

  private applications: any = [];
  private showUserProfile = false;

  private async created() {
    this.notificationStore.removeAllToasts()
  }

  private get landscapeLogoSrc() {
    const tenantLogo = this.tenantStore.tenantTheme?.logo;
    if (!tenantLogo || !tenantLogo.length) {
      return null;
    } else {
      return tenantLogo;
    }
  }


  private checkRemoveNotification(show: boolean, key: string): void {
    if (!show) {
      this.notificationStore.removeToast(key);
    }
  }

  private fullName(first: string, last: string): string {
    return `${first} ${last}`
  }

  private getAppName(appId: string | string[]): string {
    return this.userStore.apps.find((a) => a.id == Number(appId))?.name || ''
  }
}
</script>

<template>
  <div class="fill-height" :key="$vuetify.theme.themes.currentTheme">
    <v-app-bar color="primary" height="64">
      <v-progress-linear absolute location="bottom" :indeterminate="appStore.appLoading"></v-progress-linear>
      <v-row no-gutters justify="space-between">
        <v-col cols="auto" align-self="center">
          <v-row no-gutters align="center">
            <v-col cols="auto">
              <v-menu
                  v-if="userStore?.apps?.length > 1 || userStore?.isManager || userStore?.isSuperAdmin"
                  transition="scale-transition"
                  :close-on-content-click="true"
              >
                <template v-slot:activator="{ props }">
                  <div class="d-flex align-center">
                    <v-app-bar-nav-icon v-bind="props" class="ml-4">
                      <v-icon size="large">mdi-dots-grid</v-icon>
                    </v-app-bar-nav-icon>
                  </div>
                </template>
                <v-card class="pa-3" width="360">
                  <v-card-title v-if="tenantStore.tenant" class="px-0">

                    <v-img :src="landscapeLogoSrc" max-height="50" max-width="300" @loaded="logoLoaded = true" class="logo"/>
                    <span class="font-weight-bold text-h5 text-primary" v-if="!logoLoaded">{{ tenantStore.tenant.name }}</span>
                  </v-card-title>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <span class="font-weight-bold pl-1">Applications</span>
                    </v-col>
                    <v-col cols="12">
                      <v-row align="stretch" dense>
                        <template v-for="app of tenantStore.tenantApplications">
                          <v-col :key="app.id" v-if="!!tenantStore.tenantId && (userStore.apps.map((a) => a.id).includes(Number(app.id)) || userStore?.isSuperAdmin)" class="d-flex justify-center" cols="4" >
                            <v-card :disabled="!(userStore.apps.map((a) => a.id).includes(Number(app.id)) || userStore?.isSuperAdmin)" @click="userStore.setLastActiveApplication(app.id)" height="100" width="105" class="pa-2 fill-height" :variant="$route.path === `/applications/${app.id}` ? 'tonal' : 'flat'" :color="$route.path === `/applications/${app.id}` ? 'primary' : 'grey-lighten-4'" :to="`/applications/${app.id}`" >
                              <div style="height: 80px" class="d-flex flex-column justify-space-between text-center align-center">
                                <v-avatar><v-icon :class="!(userStore.apps.map((a) => a.id).includes(Number(app.id)) || userStore?.isSuperAdmin) ? 'text-disabled' : ''" size="large" :color="$route.path === `/applications/${app.id}` ? 'primary' : 'grey-darken-1'">mdi-{{ app.icon ? app.icon : 'application' }}</v-icon></v-avatar>
                                <span class="text-caption" :class="!(userStore.apps.map((a) => a.id).includes(Number(app.id)) || userStore?.isSuperAdmin) ? 'text-disabled' : ''">{{ app.name }}</span>
                              </div>
                            </v-card>
                          </v-col>
                        </template>
                        <v-col class="d-flex justify-center" cols="4" v-if="(userStore?.isManager || userStore?.isSuperAdmin) && tenantStore.tenantId">
                          <v-card min-height="80" width="105" class="pa-2" to="/manage" :variant="$route.path.includes('manage') ? 'tonal' : 'flat'" :color="$route.path.includes('manage') ? 'primary' : 'grey-lighten-4'">
                            <div style="height: 80px" class="d-flex flex-column justify-space-between text-center align-center">
                              <v-avatar><v-icon size="large" :color="$route.path.includes('manage') ? 'primary' : 'grey-darken-1'">mdi-account-cog</v-icon></v-avatar>
                              <span class="text-caption">Account management</span>
                            </div>
                          </v-card>
                        </v-col>
                        <v-col class="d-flex justify-center" cols="4" v-if="userStore?.isSuperAdmin">
                          <v-card min-height="80" width="105" class="pa-2" to="/admin" :variant="$route.path.includes('admin') ? 'tonal' : 'flat'" :color="$route.path.includes('admin') ? 'primary' : 'grey-lighten-4'">
                            <div style="height: 80px" class="d-flex flex-column justify-space-between text-center align-center">
                              <v-avatar><v-icon size="large" :color="$route.path.includes('admin') ? 'primary' : 'grey-darken-1'">mdi-tools</v-icon></v-avatar>
                              <span class="text-caption">Admin</span>
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>

              </v-menu>
            </v-col>
            <v-col>
              <v-app-bar-title class="ml-4" :text="$route.params.applicationId ? getAppName($route.params.applicationId) : ''"></v-app-bar-title>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-menu
              transition="scale-transition"
              :close-on-content-click="true">
            <template v-slot:activator="{props}">
              <v-btn icon="mdi-account" v-bind="props" class="mr-4"></v-btn>
            </template>
            <v-card width="150">
              <v-list>
                <v-list-item @click="showUserProfile = true" prepend-icon="mdi-card-account-details-outline" title="Profile"></v-list-item>
                <v-list-item v-if="tenantStore.appHelp" :href="tenantStore.appHelp" prepend-icon="mdi-help-circle-outline" title="Help"></v-list-item>
                <v-list-item @click="userStore.userLogout()" prepend-icon="mdi-logout-variant" title="Log out"></v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>


    </v-app-bar>
    <v-main class="fill-height">
      <router-view class="fill-height" :key="$route.params.applicationId"/>
      <div class="toast">
        <Toast class="mt-1" v-for="(message, key) in notificationStore.toastMessages" :key="key" :ref="key" type="error" heading="An error occurred:" :body="message" @updateShowToast="checkRemoveNotification($event, key)" />
      </div>
    </v-main>

    <v-dialog width="500" v-model="showUserProfile">
      <template v-slot:default="{ isActive }">
        <v-card title="User details">
          <v-card-text>
            <v-form>
              <v-row align="center">
                <v-col cols="12" class="pb-0">
                  <v-text-field label="Name" hide-details density="comfortable" readonly :model-value="fullName(userStore.user.first_name, userStore.user.last_name)"></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-text-field label="Email" hide-details density="comfortable" readonly :model-value="userStore.user.email" type="email"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="grey"
                @click="isActive.value = false;"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>

</template>

<style>
.toast {
  margin: 4px;
  position: absolute;
  bottom: 0;
}
.logo > img {
  width: auto !important;
}
</style>