<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import {useUserStore} from "@/stores/userStore";
import {useAppStore} from "@/stores/appStore";
import {useDisplay} from "vuetify";

@Component({
  components: {}
})
export default class Manage extends Vue {
  private mdAndUp = useDisplay().mdAndUp;
  private userStore = useUserStore();
  private appStore = useAppStore();
  private activeTab = 0;
  private created() {
    if (!this.userStore?.isManager && !this.userStore?.isSuperAdmin) {
      this.$router.replace('/')
    }
  }
}
</script>

<template>
  <div class="fill-height embed-container overflow-x-hidden" v-if="(userStore.isManager || userStore.isSuperAdmin)">
    <v-app-bar height="64" flat>
      <v-tabs v-if="mdAndUp" bg-color="background" color="primary" class="header-tabs">
        <v-tab slider-color="transparent" disabled class="enabled"><v-icon start>mdi-account-group</v-icon> Account management</v-tab>
      </v-tabs>
      <v-tabs align-tabs="center" bg-color="background" color="primary" class="menu-tabs" :style="mdAndUp ? 'margin-left: -450px' : 'margin-left: 0'">
        <v-tab exact :to="{ name: 'users' }">User management</v-tab>
        <v-tab :to="{ name: 'group-management' }" v-if="appStore.debugMode">Group management</v-tab>
      </v-tabs>
    </v-app-bar>
    <v-card variant="flat" class="rounded-0 pa-0 fill-height">
      <v-window class="fill-height">
        <v-window-item class="fill-height">
          <router-view />
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>

<style>
.enabled {
  opacity: 1 !important;
}
.header-tabs {
  width: 450px;
}
.menu-tabs {
  margin-left: -450px;
  width: 100%;
}
</style>