import {defineStore} from "pinia";
import ApplicationService from "@/services/application.service";
import {Application, Tenant} from "@/types";
import TenantService from "@/services/tenant.service";
import {getHelpUrl, isJsonString} from "@/util";

interface State {
  tenantId: number | null;
  tenant: Tenant | null;
  tenantApplications: Application[];
  defaultTheme: any;
  tenantTheme: any;
  appHelp: string | null;
}

export const useTenantStore = defineStore( 'appPortalTenantStore', {
  state: (): State => ({
    tenantId: Number(localStorage.getItem('application_framework_tenant')) || null,
    tenantApplications: [],
    tenant: null,
    tenantTheme: isJsonString(localStorage.getItem('application_framework_tenant_theme') as string) ? JSON.parse(localStorage.getItem('application_framework_tenant_theme') as string) : {},
    defaultTheme: {},
    appHelp: getHelpUrl()
  }),
  actions: {
    setHelpUrl(url: string | null) {
      this.appHelp = url;
    },
    setDefaultTheme(theme: { primary: string; secondary: string; logo: string }) {
      this.defaultTheme = theme;
    },
    async setTenantId(tenantId: number | null) {
      localStorage.setItem('application_framework_tenant', tenantId ? tenantId.toString() : '')
      this.tenantId = tenantId;
      if (tenantId) {
        await this.fetchTenantApplications(tenantId);
      }
    },
    async setTenant(tenant: Tenant | null) {
      this.tenant = tenant;
    },
    async fetchTenantApplications(tenantId: number) {
      this.tenantApplications = await ApplicationService.getApplicationsForTenant(tenantId)
    },
    async getTenantTheme(tenantId?: number) {
      if (tenantId) {
        const tenant = await TenantService.getTenant(tenantId);

        this.setTenantTheme(tenant.theme_configuration);

        if (tenant.theme_configuration?.helpUrl) {
          this.setHelpUrl(tenant.theme_configuration?.helpUrl)
        }
        return this.tenantTheme;
      }

      this.setTenantTheme(this.defaultTheme)
      return this.defaultTheme;
    },
    setTenantTheme(theme: { primary: string; secondary: string; logo: string }) {
      const stringTheme = JSON.stringify(theme);
      localStorage.setItem('application_framework_tenant_theme', stringTheme);

      this.tenantTheme = theme;
    }
  },
})