<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import {Application, Tenant} from "@/types";
import {useDisplay} from "vuetify";
import TenantService from "@/services/tenant.service";
import ApplicationService from "@/services/application.service";
import {useTenantStore} from "@/stores/tenantStore";

@Component({})
export default class AccountsManagement extends Vue {
  private tenantStore = useTenantStore();
  private mdAndUp = useDisplay().mdAndUp;
  private logo: File[] = [];
  private searchNames = '';
  private loading = false;
  private editApplicationDialog = false;
  private editAccountDialog = false;
  private removeDialog = false;
  private tenants: Tenant[] = [];
  private savedLogo = '';
  private helpUrl = '';
  private rules = [ (value: any) => { return !value || !value.length || value[0].size < 1000000 || 'Logo size should be less than 1 MB!' }];
  private currentTenant: Tenant | null = null;
  private currentApplication: Application | null = null;
  private selectedTenants: Tenant[] = [];
  private headers: Array<{ title: string; value: string; sortable: boolean; width?: string; }> = [
    { title: 'Name', value: 'name', sortable: true, width: '20%' },
    { title: 'Status', value: 'state', sortable: true, width: '20%' },
    { title: 'Applications', value: 'applications', sortable: false, width: '30%' },
    { title: '', value: 'access', sortable: false },
    { title: '', value: 'actions', sortable: false },
  ];
  private tenant = {
    name: '',
    state: 'active'
  }
  private newApp: Application | null = null;
  private newApps: Partial<Application>[] = [];
  private applicationsToRegister: Application[] = [];
  private applications: Application[] = [];

  private primary = '';
  private secondary = '';
  private primaryDialog = false;
  private secondaryDialog = false;

  private async mounted() {
    this.loading = true;
    try {
      await Promise.all([this.fetchTenants(), this.fetchApplications()])
    } finally {
      this.loading = false;
    }
    this.primary = this.$vuetify.theme.themes.currentTheme.colors.primary;
    this.secondary = this.$vuetify.theme.themes.currentTheme.colors.secondary;
  }

  private async fetchApplications() {
    this.applications = await ApplicationService.getAllApplications();
  }

  private async fetchTenants() {
    this.tenants = await TenantService.getTenants();
  }

  private async addTenant() {
    this.loading = true;
    try {
      const apps = [...this.applicationsToRegister];
      const tenant = await TenantService.addTenant(this.tenant);
      if (tenant) {
        this.currentTenant = { ...tenant, applications: apps };
        await this.saveTenant();
      }
    } finally {
      await this.fetchTenants();
      this.loading = false;
    }
  }

  private editAccount(item: Tenant) {
    this.currentTenant = {...item};
    this.primary = this.currentTenant.theme_configuration?.primary || this.$vuetify.theme.global.current.colors.primary;
    this.secondary = this.currentTenant.theme_configuration?.secondary || this.$vuetify.theme.global.current.colors.secondary;
    this.savedLogo = this.currentTenant.theme_configuration?.logo || '';
    this.helpUrl = this.currentTenant.theme_configuration?.helpUrl || '';

    this.editAccountDialog = true;
  }

  private async removeTenant() {
    // Refactor for bulk remove
    this.loading = true;
    try {
      for (const tenant of this.selectedTenants) {
        await TenantService.removeTenant(tenant);
      }
    } finally {
      this.loading = false;
      this.selectedTenants = [];
      await this.fetchTenants();
    }
  }


  private async toggleSuspendTenant() {
    // Refactor for bulk suspend
    for (const tenant of this.selectedTenants) {
      if (tenant.state === 'suspended') {
        tenant.state = 'active';
      } else {
        tenant.state = 'suspended';
      }
      await TenantService.updateTenant(tenant);
    }
    this.selectedTenants = [];
    await this.fetchTenants();
  }

  private selectApp(app: Application) {
    this.newApps.push(app);
  }

  private addApplicationRow(tenant: Tenant | null) {
    if (tenant) {
      this.newApps.push({ name: '', description: '' });
    }
  }

  private addAllApps(tenant: Tenant | null) {
    if (tenant) {
      tenant.applications = [];
      this.newApps = [];
      this.applicationsToRegister = this.applications;
    }
  }

  private removeAllApps(tenant: Tenant | null) {
    if (tenant) {
      tenant.applications = [];
      this.newApps = [];
      this.applicationsToRegister = [];
    }
  }

  private addApplicationAccess() {
    if (this.newApp && (this.currentTenant || this.tenant)) {
      this.applicationsToRegister = this.applicationsToRegister || []
      this.applicationsToRegister.push(this.newApp);
      this.newApp = null;
      this.newApps = [];
    }
  }
  private unregisterApplication(app: Application, saveTenant = false) {
    if (this.currentTenant) {
      this.currentTenant.applications = this.currentTenant.applications?.filter((a) => a.id !== app.id);
    }
    if (saveTenant) {
      this.saveTenant();
    }
  }
  private removeNewApplication(app: Application) {
    this.applicationsToRegister = this.applicationsToRegister.filter((a) => a.id !== app.id);
  }

  private async saveTenant() {
    this.loading = true;
    if (this.currentTenant) {
      if (this.currentTenant.applications && this.applicationsToRegister.length) {
        this.currentTenant.applications.push(...this.applicationsToRegister);
        this.applicationsToRegister = [];
      }

      const toBase64 = (file: File): Promise<any> => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
      this.currentTenant.theme_configuration = this.currentTenant.theme_configuration || { primary: '', secondary: '', logo: '', helpUrl: '' };
      this.currentTenant.theme_configuration.primary = this.primary;
      this.currentTenant.theme_configuration.secondary = this.secondary;
      if (this.logo[0]) {
        this.currentTenant.theme_configuration.logo = await toBase64(this.logo[0]);
        this.savedLogo = this.currentTenant.theme_configuration.logo;
      } else if (!this.logo[0] && !this.savedLogo) {
        this.currentTenant.theme_configuration.logo = '';
      }
      if (this.helpUrl && this.helpUrl.trim().length) {
        this.currentTenant.theme_configuration.helpUrl = this.helpUrl.trim();
      } else {
        this.currentTenant.theme_configuration.helpUrl = '';
      }
      try {
        await TenantService.updateTenant(this.currentTenant);
        await this.fetchTenants();
        if (this.tenantStore.tenantId) {
          await this.tenantStore.fetchTenantApplications(this.tenantStore.tenantId)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false;
        this.editAccountDialog = false;
        this.editApplicationDialog = false;
        this.removeDialog = false;

        if (this.tenantStore.tenantId) {
          await this.tenantStore.getTenantTheme(this.tenantStore.tenantId)

          this.$vuetify.theme.themes.currentTheme = {
            ...this.$vuetify.theme.themes.currentTheme,
            colors: {
              ...this.$vuetify.theme.themes.currentTheme.colors,
              primary: this.tenantStore.tenantTheme?.primary || this.$vuetify.theme.themes.currentTheme.colors?.primary || this.tenantStore.defaultTheme.primary,
              secondary: this.tenantStore.tenantTheme?.secondary || this.$vuetify.theme.themes.currentTheme.colors?.secondary || this.tenantStore.defaultTheme.secondary
            }
          }
        }
      }
    }
  }

  private manageTenant(tenant: Tenant, tenantId: number) {
    this.tenantStore.setTenantId(tenantId);
    this.tenantStore.setTenant(tenant);
    this.$router.push({name: 'users'})
  }

  private async clearTenant() {
    this.tenantStore.tenantId = null;
    this.tenantStore.setTenantId(null);
    this.tenantStore.setTenant(null);
    this.tenantStore.setHelpUrl(null);

    await this.tenantStore.getTenantTheme();

    if (this.$route.name !== 'accounts') {
      this.$router.replace({name: 'accounts'})
    } else {
      if (this.$vuetify.theme.themes.currentTheme.colors.primary !== this.tenantStore.tenantTheme?.primary || this.$vuetify.theme.themes.currentTheme.colors.secondary !== this.tenantStore.tenantTheme?.secondary) {
        this.$vuetify.theme.themes.currentTheme = {
          ...this.$vuetify.theme.themes.currentTheme,
          colors: {
            ...this.$vuetify.theme.themes.currentTheme.colors,
            primary: this.tenantStore.tenantTheme?.primary || this.$vuetify.theme.themes.currentTheme.colors.primary,
            secondary: this.tenantStore.tenantTheme?.secondary || this.$vuetify.theme.themes.currentTheme.colors.secondary
          }
        }
      }
    }
  }

  private highlightActiveTenant(row: any) {
    if (row.item.id === this.tenantStore.tenantId) {
      return { style: 'background-color: #F4F4F4' }
    }
  }

  private get filteredApplications() {
    return this.applications.filter((app) => {
      if (this.applicationsToRegister.length) {
        const addingApp = this.applicationsToRegister.map((atr) => atr.id).includes(app.id);
        return !addingApp;
      } else {
        let tenant: any = this.currentTenant?.id ? this.currentTenant : this.tenant;
        const hasApp = tenant.applications?.map((a: Application) => a.id).includes(app.id);
        return !hasApp;
      }
    });
  }
}
</script>

<template>
  <div>
    <v-row justify="space-between" align="center" dense>
      <v-col cols="12">
        <v-row align="center" dense>
          <v-col cols="12">
            <v-card color="#F4F4F4" class="pa-2 align-content-center" variant="flat" height="48">
              <v-row dense align="center">
                <v-col cols="8">
                  <v-row no-gutters align="center">
                    <v-col cols="3" md="auto">
                      <v-dialog width="500" @update:modelValue="tenant.name = ''; applicationsToRegister = [];">
                        <template v-slot:activator="{ props }">
                          <v-btn v-bind="props" size="small" variant="plain" color="primary">
                            {{ mdAndUp ? 'Add account' : 'Add' }}
                          </v-btn>
                        </template>

                        <template v-slot:default="{ isActive }">
                          <v-card title="Add an account">
                            <v-card-text>
                              <v-form>
                                <v-row>
                                  <v-col cols="12">
                                    <v-text-field hide-details density="compact" label="Name" v-model="tenant.name"></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row align="center">
                                  <v-col cols="12" class="pb-0">
                                    <v-btn block rounded="pill" color="primary" variant="flat" @click="addApplicationRow(tenant)" :disabled="!filteredApplications.length"> Add application </v-btn>
                                  </v-col>
                                  <v-col cols="6" class="pb-0">
                                    <v-btn block rounded="pill" color="primary" variant="flat" @click="addAllApps(tenant)" :disabled="!filteredApplications.length">Add all apps</v-btn>
                                  </v-col>
                                  <v-col cols="6" class="pb-0">
                                    <v-btn block rounded="pill" color="primary" variant="flat" @click="removeAllApps(tenant)">Remove all apps</v-btn>
                                  </v-col>
                                  <v-col cols="12" class="pt-0">
                                    <v-list>
                                      <v-list-item v-for="app of applicationsToRegister" :key="app.name" style="background-color: rgba(0,0,0,0.05)" rounded class="my-2">
                                        <v-row no-gutters justify="space-between" align="center">
                                          <v-col cols="11">
                                            <v-list-item-title>{{ app.name }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
                                          </v-col>
                                          <v-col cols="1">
                                            <v-list-item-action>
                                              <v-btn icon variant="flat" color="transparent" size="32" @click="removeNewApplication(app)"><v-icon icon="mdi-delete" color="primary" size="24"></v-icon></v-btn>
                                            </v-list-item-action>
                                          </v-col>
                                        </v-row>
                                      </v-list-item>
                                      <v-list-item
                                          class="mb-1 px-0 pt-0"
                                          v-for="app of newApps"
                                          :key="app.name"
                                      >
                                        <v-combobox
                                            class="app-combobox"
                                            clearable
                                            :items="filteredApplications"
                                            item-title="name"
                                            hide-details
                                            density="comfortable"
                                            placeholder="Search applications"
                                            return-object
                                            :menu-props="{ maxWidth: 452 }"
                                        >
                                          <template v-slot:selection="{ item }">
                                            <div class="d-flex flex-column">
                                              <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                                              <v-list-item-subtitle>{{ item.raw.description }}</v-list-item-subtitle>
                                            </div>
                                          </template>
                                          <template v-slot:item="{ item }">
                                            <div class="d-flex flex-column">
                                              <v-list-item @click="newApp = item.raw; addApplicationAccess()">
                                                <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.raw.description }}</v-list-item-subtitle>
                                              </v-list-item>
                                            </div>
                                          </template>
                                        </v-combobox>
                                      </v-list-item>
                                    </v-list>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>

                              <v-btn
                                  :loading="loading"
                                  :disabled="!tenant.name || loading"
                                  color="primary"
                                  @click="addTenant(); isActive.value = false"
                              >
                                Add account
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-col>
                    <v-col cols="4" md="auto">
                      <v-btn size="small" variant="plain" :disabled="!selectedTenants.length" color="primary" @click="toggleSuspendTenant">
                        {{ (mdAndUp ? (selectedTenants.length && selectedTenants[0].state === 'active' ? 'Suspend account' : 'Activate account') : (selectedTenants.length && selectedTenants[0].state === 'active' ? 'Suspend' : 'Activate')) }}
                      </v-btn>
                    </v-col>
                    <v-col cols="4" md="auto">
                      <v-dialog width="500">
                        <template v-slot:activator="{ props }">
                          <v-btn v-bind="props" size="small" variant="plain" :disabled="!selectedTenants.length" color="primary">
                            {{ mdAndUp ? 'Remove account' : 'Remove' }}
                          </v-btn>
                        </template>
                        <template v-slot:default="{ isActive }">
                          <v-card max-width="460">
                            <v-card-title>Remove {{ selectedTenants[0]?.name || 'account' }}?</v-card-title>
                            <v-card-text>
                              Are you sure you want to remove this account?
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                  @click="isActive.value = false">
                                Cancel
                              </v-btn>
                              <v-btn
                                  :loading="loading"
                                  :disabled="loading"
                                  color="primary"
                                  @click="removeTenant(); isActive.value = false">
                                Remove
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
        :loading="loading"
        color="primary"
        :items="tenants"
        :headers="headers"
        show-select
        v-model="selectedTenants"
        return-object
        height="calc(100vh - 260px)"
        fixed-header
    >
      <template v-slot:[`item.applications`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex" v-for="(app, index) of item.applications" :key="app.id">
            <div class="mr-n2">
              <v-tooltip :text="app.name" location="bottom" open-delay="200" v-if="index < 5">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" size="32" style="border: 2px solid #ffffff" icon variant="flat" color="primary" @click="currentTenant = {...item}; currentApplication = app; removeDialog = true;">
                    <span v-if="!app.icon">{{ app.name.charAt(0) }}</span>
                    <v-icon v-else color="white" size="18">mdi-{{ app.icon }}</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </div>
          </div>
          <v-tooltip v-if="item.applications.length > 5" location="bottom" open-delay="200">
            <template v-slot:activator="{ props }">
              <div v-bind="props" class="ml-4"><a class="link">+ {{ item.applications.length - 5 }} more</a></div>
            </template>
            <template v-slot:default>
              <div v-for="app of item.applications.slice(5)" :key="app.id">{{ app.name }}</div>
            </template>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <div v-if="item.state === 'active'" class="d-flex">
          <v-icon start icon="mdi-circle" color="success"></v-icon>
          Active
        </div>
        <div v-if="item.state === 'suspended'" class="d-flex">
          <v-icon start icon="mdi-circle" color="amber"></v-icon>
          Suspended
        </div>
      </template>
      <template v-slot:[`item.access`]="{ item }">
        <v-btn width="160" size="small" height="32" variant="flat" rounded="pill" @click="manageTenant(item, item.id)" color="primary" v-if="item.state !== 'suspended' && item.id !== tenantStore.tenantId">Access account <v-icon end>mdi-arrow-right</v-icon></v-btn>
        <v-btn width="160" size="small" height="32" variant="flat" rounded="pill" @click="clearTenant(item.id)" color="#f4f4f4" v-else-if="item.id === tenantStore.tenantId"><span class="text-primary">Exit account</span></v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu>
          <template #activator="{ props }">
            <div class="d-flex justify-end">
              <v-btn v-bind="props" size="32" icon variant="flat" color="primary" v-if="item.state !== 'suspended'"><v-icon size="20">mdi-dots-vertical</v-icon></v-btn>
            </div>
          </template>
          <v-list density="compact">
            <v-list-item @click="currentTenant = {...item}; editAccount(item);" prepend-icon="mdi-pencil" title="Edit account"></v-list-item>
            <v-list-item @click="currentTenant = {...item}; editApplicationDialog = true;" prepend-icon="mdi-application-edit" title="Edit applications"></v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <v-dialog width="500" v-model="removeDialog">
      <template v-slot:default="{ isActive }">
        <v-card max-width="460">
          <v-card-title>Remove {{ currentApplication.name }} from {{ currentTenant.name }}?</v-card-title>
          <v-card-text>
            Are you sure you want to remove this application from this account? Users in this account will no longer have access to the application or its data.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                @click="isActive.value = false">
              Cancel
            </v-btn>
            <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                @click="unregisterApplication(currentApplication, true); isActive.value = false">
              Remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog width="500" v-model="editApplicationDialog" @update:model-value="applicationsToRegister = [];" @afterLeave="currentTenant = null">
      <template v-slot:default>
        <v-card title="And or remove account applications">
          <v-card-text>
            <v-form>
              <v-row align="center">
                <v-col cols="12" class="pb-0">
                  <v-btn block rounded="pill" color="primary" variant="flat" @click="addApplicationRow(currentTenant)" :disabled="!filteredApplications.length">Add application</v-btn>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-btn block rounded="pill" color="primary" variant="flat" @click="addAllApps(currentTenant)" :disabled="!filteredApplications.length">Add all apps</v-btn>
                </v-col>
                <v-col cols="6" class="pb-0">
                  <v-btn block rounded="pill" color="primary" variant="flat" @click="removeAllApps(currentTenant)">Remove all apps</v-btn>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-list>
                    <v-list-item v-for="app of currentTenant?.applications" :key="app.name" style="background-color: rgba(0,0,0,0.05)" rounded class="my-2">
                      <v-row no-gutters class="flex-nowrap" justify="space-between" align="center">
                        <v-col cols="11">
                          <v-list-item-title>{{ app.name }}</v-list-item-title>
                          <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
                        </v-col>
                        <v-col cols="1">
                          <v-list-item-action>
                            <v-dialog :attach="true">
                              <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" icon variant="flat" color="transparent" size="32"><v-icon icon="mdi-delete" color="primary" size="24"></v-icon></v-btn>
                              </template>
                              <template v-slot:default="{ isActive }">
                                <v-card max-width="460">
                                  <v-card-title>Remove application from account</v-card-title>
                                  <v-card-text>
                                    Are you sure you want to remove this application from this account? Users in this account will no longer have access to the application or its data.
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="isActive.value = false">
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                        :loading="loading"
                                        :disabled="loading"
                                        color="primary"
                                        @click="unregisterApplication(app); isActive.value = false">
                                      Remove
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </template>
                            </v-dialog>
                          </v-list-item-action>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item v-for="app of applicationsToRegister" :key="app.name" style="background-color: rgba(0,0,0,0.05)" rounded class="my-2">
                      <v-row no-gutters justify="space-between" align="center">
                        <v-col cols="11">
                          <v-list-item-title>{{ app.name }}</v-list-item-title>
                          <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
                        </v-col>
                        <v-col cols="1">
                          <v-list-item-action>
                            <v-btn icon variant="flat" color="transparent" size="32" @click="removeNewApplication(app)"><v-icon icon="mdi-delete" color="primary" size="24"></v-icon></v-btn>
                          </v-list-item-action>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item
                        class="mb-1 px-0 pt-0"
                        v-for="app of newApps"
                        :key="app.name"
                    >
                      <v-combobox
                          class="app-combobox"
                          clearable
                          :items="filteredApplications"
                          item-title="name"
                          hide-details
                          density="comfortable"
                          placeholder="Search applications"
                          return-object
                          :menu-props="{ maxWidth: 452 }"
                      >
                        <template v-slot:selection="{ item }">
                          <div class="d-flex flex-column">
                            <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.raw.description }}</v-list-item-subtitle>
                          </div>
                        </template>
                        <template v-slot:item="{ item }">
                          <div class="d-flex flex-column">
                            <v-list-item @click="newApp = item.raw; addApplicationAccess()">
                              <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                              <v-list-item-subtitle>{{ item.raw.description }}</v-list-item-subtitle>
                            </v-list-item>
                          </div>
                        </template>
                      </v-combobox>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                @click="saveTenant"
                :loading="loading"
                :disabled="loading"
            >
              Save account
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog width="500" v-model="editAccountDialog">
      <template v-slot:default>
        <v-card title="Edit account">
          <v-card-text>
            <v-form>
              <v-row align="center">
                <v-col cols="12" class="pb-0">
                  <p>
                    Account details
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Name" hide-details density="comfortable" v-model="currentTenant.name"></v-text-field>
                </v-col>

                <v-col cols="12" v-if="!currentTenant?.theme_configuration?.logo || !savedLogo" class="pb-0">
                  <v-file-input
                      color="primary" clearable :label="!logo.length ? 'Select or drop a logo' : ''" density="comfortable" prepend-icon="" v-model="logo"
                      counter
                      counter-size-string="Max 1MB"
                      show-size
                      :rules="rules"
                      accept="image/png">
                    <template v-slot:append-inner>
                      <v-icon color="primary" icon="mdi-image" v-if="!logo.length"></v-icon>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" v-if="currentTenant?.theme_configuration?.logo">
                  <v-row dense v-if="savedLogo">
                    <v-col cols="12" class="d-flex justify-center">
                      <v-img :src="savedLogo" alt="logo" width="200" height="64"></v-img>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <v-btn size="small" color="primary" variant="plain" @click="savedLogo = ''; logo = [];">Clear logo</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" class="pt-0">
                  <v-text-field label="Primary colour" hide-details density="comfortable" v-model="primary" color="primary">
                    <template v-slot:append-inner>
                      <v-menu :close-on-content-click="false" v-model="primaryDialog">
                        <template v-slot:activator="{ props }">
                          <v-icon :color="primary" v-bind="props" icon="mdi-palette"></v-icon>
                        </template>
                        <v-color-picker mode="hex" v-model="primary"></v-color-picker>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6" class="pt-0">
                  <v-text-field label="Secondary colour" hide-details density="comfortable" v-model="secondary" color="secondary">
                    <template v-slot:append-inner>
                      <v-menu :close-on-content-click="false" v-model="secondaryDialog">
                        <template v-slot:activator="{ props }">
                          <v-icon :color="secondary" v-bind="props" icon="mdi-palette"></v-icon>
                        </template>
                        <v-color-picker mode="hex" v-model="secondary" ></v-color-picker>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field :clearable="true" label="Help section URL" density="comfortable" v-model="helpUrl" persistent-hint hint="Provide a URL for the custom help section for this account or leave this field blank to use the default"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                @click="saveTenant"
                :loading="loading"
                :disabled="loading"
            >
              Save account
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<style>
.link {
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
}
</style>
